<div class="row text-center">
    <div class="col">
        <img src="/assets/img/user.png" width="64px" height="64px">
        <h3>Bienvenido: {{name}} {{lastname}}</h3>
        <p><small>{{phone}}</small></p>
        <p>En este portal encontrará varias herramientas para la gestión de pedidos.</p>
        <div class="row">
            <div class="col"></div><div class="col"></div><div class="col"></div>
            <div class="col">
                <a routerLink="../tables/result"><img src="assets/img/mainmenu/resultados.png" width="110px" height="110px"></a>
                <p><b>Reportes</b></p>
                <p>Permite revisar los reportes de pedidos	</p>
            </div>
            <div class="col">
                <a routerLink="../cargarPedidos"><img src="assets/img/sync.png" width="110px" height="110px"></a>
                <p><b>Cargar pedidos</b></p>
                <p>Permite subir un pedido para ser procesado	</p>
            </div>
            <div class="col">
                <a routerLink="../semaphore"><img src="assets/img/mainmenu/Semaphore.png" width="110px" height="150px"></a>
                <p><b>Semáforo</b></p>
                <p>Permite revisar los reportes en modo 'Semáforo'	</p>
            </div>
            <div class="col"></div><div class="col"></div><div class="col"></div>
    </div>
    <div class="row">
        <div class="col"></div><div class="col"></div><div class="col"></div>
        <div class="col">
            <a routerLink="../mape"><img src="assets/img/mainmenu/RForecast.png" width="110px" height="110px"></a>
            <p><b>Real vs Forecast</b></p>
            <p>Permite comparar predicciones contra valores reales	</p>
        </div>
        <div class="col">
            <a routerLink="../forecast"><img src="assets/img/mainmenu/Forecast.png" width="110px" height="110px"></a>
            <p><b>Forecast BRP</b></p>
            <p>Permite generar un pedido en base a predicciones	</p>
        </div>
        <div class="col">
            <a routerLink="../help"><img src="assets/img/mainmenu/Help.png" width="110px" height="110px"></a>
            <p><b>Ayuda</b></p>
            <p>Permite entrar al manual del usuario	</p>
        </div>
        <div class="col"></div><div class="col"></div><div class="col"></div>
    </div>

    <div class="row">
        <div class="col"></div><div class="col"></div><div class="col"></div>
        <div class="col">
            <a routerLink="../config"><img src="assets/img/mainmenu/config.png" width="110px" height="110px"></a>
            <p><b>Configuración del intérprete de pedidos</b></p>
            <p>Permite modificar productos del intérprete de pedidos	</p>
        </div>
        <div class="col">
            <a routerLink="../factor"><img src="assets/img/mainmenu/mesa-de-edicion.png" width="110px" height="150px"></a>
            <p><b>Factor de importación</b></p>
            <p>Permite revisar los reportes de importación	</p>
        </div>
        <div class="col">
            <a ></a>
            <p><b></b></p>
            <p></p>
        </div>
        <div class="col"></div><div class="col"></div><div class="col"></div>
    </div>
    
    
</div>
</div>