<div class="row">
    <div class="col">
        
    </div>
    <div class="col">
        <div *ngIf="userChangedPassword" class="alert alert-success" role="alert">
            Contraseña cambiada con exito!
        </div>
        <div *ngIf="errorInChange"class="alert alert-danger" role="alert">
            <h3>Error</h3>
            {{error}}
        </div>
    </div>
    <div class="col"></div>
</div>
<div class="row" *ngIf="!userReceivedCode">
    <div class="col"></div>
    <div class="col">
        <form #requestForm="ngForm" (ngSubmit)="recoverPassword(requestForm)">
        <h3>Correo</h3>
        <input type="text" name="email" required email [(ngModel)]="user.email" class="form-control">
        <span *ngIf="requestForm.submitted && requestForm.controls['email'].errors"
                          class="text-danger animated fadeIn">Ingresa tu email</span>
        <br>
        <button type="submit" class="btn btn-success btn-lg btn-block">Recuperar password</button>
    </form>
    </div>
    <div class="col"></div>
    </div>
 <div class="row" *ngIf="userReceivedCode">
    <div class="col"></div>
     <div class="col">
        <form #submitForm="ngForm" (ngSubmit)="recoverPasswordSubmit(submitForm)">
        <h3>Correo</h3>
        <input type="text" name="email" readonly required email [(ngModel)]="user.email" class="form-control">
        <span *ngIf="submitForm.submitted && submitForm.controls['email'].errors"
                          class="text-danger animated fadeIn">Ingresa tu email</span>
        <br>
        <h3>Código de verificación (Requerido)</h3>
        <input type="text" name="verificationCode" required verificationCode [(ngModel)]="verificationCode" class="form-control">
        <span *ngIf="submitForm.submitted && submitForm.controls['verificationCode'].errors"
                          class="text-danger animated fadeIn">Ingresa el número de verificación</span>
        <br>
        <h3>Nueva contraseña (Requerido)</h3>
        <input type="password" name="password" required password [(ngModel)]="user.password" class="form-control">
        <span *ngIf="submitForm.submitted && submitForm.controls['password'].errors"
                          class="text-danger animated fadeIn">Ingresa tu nueva contraseña</span>
        <br>
        <button type="submit" class="btn btn-success btn-lg btn-block">Recuperar password</button>
    </form>
     </div>
     <div class="col"></div>
 </div>   
