import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {UserModel} from '../../models/user.model'
import {AuthService} from '../../services/auth.service'

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html'
})
export class RecoverPasswordComponent implements OnInit {
  error:string='';
  errorInChange:boolean=false;
  userReceivedCode:boolean=false;
  userChangedPassword:boolean=false;
  user:UserModel;
  verificationCode:string;
  constructor(private auth:AuthService) { 
    this.user = new UserModel();
  }
  recoverPassword(form:NgForm){
    if(form.invalid){
      return;
    }
      this.auth.forgotPassword(this.user.email).then((data)=>{
      this.errorInChange=false;
      this.userReceivedCode=true;

    },(error)=>{
    this.errorInChange=true;
    this.error=error['message']});
  }
  recoverPasswordSubmit(form:NgForm){
    if(form.invalid){
      return;
    }
    this.auth.recoverPassword(this.user.email,this.verificationCode,this.user.password).then((data)=>{
    this.errorInChange=false;
    this.userChangedPassword=true;
    },(error)=>{
      this.errorInChange=true;
      this.error=error['message']
    })
  }

  ngOnInit(): void {
  }

}
