import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'rxjs/operators';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  eliminarObjetosDuplicados(arr, prop) {
    var nuevoArray = [];
    var lookup = {};
    for (var i in arr) {
      lookup[arr[i][prop]] = arr[i];
    }
    for (i in lookup) {
      nuevoArray.push(lookup[i]);
    }
    return nuevoArray;
  }
  oSum(resultPost, code, value, compareValue) {
    const res = resultPost.reduce((total, item) => {
      let iterationValue
      if (code === item[compareValue]) {
        iterationValue = item[value]
      } else {
        iterationValue = 0.0
      }
      return total + iterationValue
    }, 0)
    return res;
  }
  getMergedSums(resultPost: any,op:number): any {
    let searchValue='';
    switch(op){
      case 1:
        searchValue = 'name'
      break;
      case 2:
        searchValue = 'item_group_code'
      break;
      case 3:
        searchValue = 'item_code'
      break;
      case 4:
        searchValue='group_code';
      break;
      case 6:
        searchValue='document'
      break;  
      default:
        searchValue = 'code'  
      break;  
    }
    let data = [];
    const names = this.eliminarObjetosDuplicados(resultPost,searchValue).map((value: any) => value[searchValue]);
    const filteredData = this.eliminarObjetosDuplicados(resultPost,searchValue);
    for (const i in names) {
      /*data.push({
        code: filteredData[i].code, name: filteredData[i].name, group_code: filteredData[i].group_code, group_name: filteredData[i].group_name, document: filteredData[i].document, date: filteredData[i].date, due_date: filteredData[i].due_date, profitFC: filteredData[i].profitFC, marginFC: filteredData[i].marginFC, profit_line_FC: this.oSum(resultPost,names[i],'profit_line_FC',searchValue), profitLine: this.oSum(resultPost,names[i],'profitLine',searchValue),
        total: this.oSum(resultPost,names[i],'line_total',searchValue), profit: filteredData[i].profit, margin: filteredData[i].margin, totalFC: this.oSum(resultPost,names[i],'total_line_FC',searchValue), rate: filteredData[i].rate, line: filteredData[i].line, ship_date: filteredData[i].ship_date, item_code: filteredData[i].item_code, description: filteredData[i].description, quantity: this.oSum(resultPost, names[i], 'quantity',searchValue),
        price: this.oSum(resultPost, names[i], 'price',searchValue), cost: this.oSum(resultPost,names[i],'cost',searchValue), line_total: this.oSum(resultPost, names[i], 'line_total',searchValue), margin_line: filteredData[i].margin_line, total_line_FC: this.oSum(resultPost, names[i], 'total_line_FC',searchValue), item_group_code: filteredData[i].item_group_code, item_group_name: filteredData[i].item_group_name, currency: filteredData[i].currency
      })*/
      let tmpObj = {
        code: filteredData[i].code, name: filteredData[i].name, group_code: filteredData[i].group_code, group_name: filteredData[i].group_name, document: filteredData[i].document, date: filteredData[i].date, due_date: filteredData[i].due_date,total: this.oSum(resultPost,names[i],'line_total',searchValue), profit: this.oSum(resultPost,names[i],'profitLine',searchValue), margin: 0.0/*this.oSum(resultPost,names[i],'margin',searchValue) */, 
        totalFC: this.oSum(resultPost,names[i],'total_line_FC',searchValue),profitFC: this.oSum(resultPost,names[i],'profit_line_FC',searchValue), rate: filteredData[i].rate, currency: filteredData[i].currency, 
        line: filteredData[i].line, ship_date: filteredData[i].ship_date, item_code: filteredData[i].item_code, description: filteredData[i].description,  quantity: this.oSum(resultPost, names[i], 'quantity',searchValue), cost: this.oSum(resultPost,names[i],'cost',searchValue), price: this.oSum(resultPost, names[i], 'price',searchValue), line_total: this.oSum(resultPost, names[i], 'line_total',searchValue), profitLine: this.oSum(resultPost,names[i],'profitLine',searchValue),  margin_line: filteredData[i].margin_line, total_line_FC: this.oSum(resultPost, names[i], 'total_line_FC',searchValue),
        profit_line_FC: this.oSum(resultPost,names[i],'profit_line_FC',searchValue), item_group_code: filteredData[i].item_group_code, item_group_name: filteredData[i].item_group_name
      }
      if(isFinite(tmpObj.profit/tmpObj.total)){
        tmpObj.margin = tmpObj.profit/tmpObj.total;
      } else {      
        tmpObj.margin = 0;
    }
      data.push(tmpObj);
    }
    return data;
  }
  getSums(resultPost: any): any {
    let sums: any = {
      sumElements: 0,
      sumDocEntry: 0.0,
      sumDocTotal: 0.0,
      sumDocTotalFC: 0.0,
      sumQuantity: 0,
      sumPrice: 0.0,
      sumLineTotal: 0.0,
      sumGrossProf: 0.0,
      sumGrossProfFC: 0.0,
      sumGrssProfit: 0.0,
      sumGrssProfitFC: 0.0,
      sumTotalFC: 0.0,
      sumCost: 0.0,
      sumDetailsMargin: 0.0,
      sumHeadersMargin: 0.0,
    }
    for (let p of resultPost) {
      sums.sumElements++;
      sums.sumDocEntry += p.document;
      sums.sumDocTotal += p.total;
      sums.sumDocTotalFC += p.totalFC;
      sums.sumQuantity += p.quantity;
      sums.sumPrice += p.price;
      sums.sumLineTotal += p.line_total;
      sums.sumGrossProf += p.profit;
      sums.sumGrossProfFC += p.profitFC;
      sums.sumGrssProfit += p.profitLine;
      sums.sumGrssProfitFC += p.profit_line_FC;
      sums.sumTotalFC += p.total_line_FC;
      sums.sumCost += p.cost;
      
    }
    if(isFinite(sums.sumGrssProfit/sums.sumLineTotal)){
       sums.sumDetailsMargin = sums.sumGrssProfit/sums.sumLineTotal;
    }else {
       sums.sumDetailsMargin = 0;
    }
    if(isFinite(sums.sumGrossProf/sums.sumDocTotal)){
    sums.sumHeadersMargin = sums.sumGrossProf/sums.sumDocTotal;
    }else{
      sums.sumHeadersMargin = 0;
    }
    return sums;
  }
  jsDate(date:string):string{
    const aux=date.split('-');
    return aux[1]+'-'+aux[0]+'-'+aux[2];
  }
  transform(value: any, ...args: any[]): any {
    let resultPost = [];
    let sums = [];
    let attr = 'name';
    try {
      //console.log(args)
      /*Filtra mediante la barra de búsqueda usada*/
      if (args[2]) {
        attr = args[2];
      }
      /*Filtra si el usuario escogió cabeceras
      if (args[1] == true) {
        value = this.eliminarObjetosDuplicados(value, 'document');
      }*/
      for (const post of value) {
        let valor: any = args[0].toString().toUpperCase();
        /*Extrae sólo con la moneda seleccionada*/
        if (args[7] == true) {
          attr = 'currency';
          valor = args[8];
        }
        /*main(): Compara si los resultados son similares o iguales a lo que se mando en las barras de búsqueda*/
        if (post[attr].toString().indexOf(valor) > -1) {
          resultPost.push(post);
        }
      }
    }
    catch (error) { }
    /*Si el usaurio usa filtro de fechas */
    if (args[4] == true) {
      if (args[5] && args[6]) {
      let dateFiltered = resultPost.filter((data: any) => {
        var time = new Date(this.jsDate(data.ship_date)).getTime();
        var sd = new Date(args[5]).getTime();
        var ed = new Date(args[6]).getTime();
        return time >= sd && time <= ed + 86400000;
      });
      
        resultPost = dateFiltered;
      }
    }
    /*Si el usuario consolida/compacta los datos*/
    if (args[3] == true) {
      resultPost = this.getMergedSums(resultPost,args[9]);
    }
    /*Suma de cantidades */
    sums = this.getSums(resultPost);
    resultPost.push(sums);
    return resultPost;
  }
}