// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const API:string = 'https://3uyoh9h5zh.execute-api.us-east-1.amazonaws.com/prod';
export const APIN:string = 'https://dkh94m66n1.execute-api.us-east-1.amazonaws.com/prod';


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// {
//   "name": "web-demo",
//   "version": "0.0.0",
//   "scripts": {
//     "ng": "ng",
//     "start": "ng serve",
//     "build": "ng build",
//     "test": "ng test",
//     "lint": "ng lint",
//     "e2e": "ng e2e"
//   },
//   "private": true,
//   "dependencies": {
//     "@angular/animations": "~11.0.1",
//     "@angular/common": "~11.0.1",
//     "@angular/compiler": "~11.0.1",
//     "@angular/core": "~11.0.1",
//     "@angular/forms": "~11.0.1",
//     "@angular/platform-browser": "~11.0.1",
//     "@angular/platform-browser-dynamic": "~11.0.1",
//     "@angular/router": "~11.0.1",
//     "@sweetalert2/ngx-sweetalert2": "^9.0.0",
//     "angular-file-saver": "^1.1.3",
//     "aws-amplify": "^3.3.11",
//     "aws-sdk": "^2.843.0",
//     "file-saver": "^1.3.8",
//     "json-2-csv": "^3.10.0",
//     "rxjs": "~6.6.0",
//     "sweetalert2": "^10.16.2",
//     "tslib": "^2.0.0",
//     "zone.js": "~0.10.2"
//   },
//   "devDependencies": {
//     "@angular-devkit/build-angular": "~0.1100.2",
//     "@angular/cli": "~11.0.2",
//     "@angular/compiler-cli": "~11.0.1",
//     "@types/jasmine": "~3.6.0",
//     "@types/node": "^12.11.1",
//     "codelyzer": "^6.0.0",
//     "jasmine-core": "~3.6.0",
//     "jasmine-spec-reporter": "~5.0.0",
//     "karma": "~5.1.0",
//     "karma-chrome-launcher": "~3.1.0",
//     "karma-coverage": "~2.0.3",
//     "karma-jasmine": "~4.0.0",
//     "karma-jasmine-html-reporter": "^1.5.0",
//     "protractor": "~7.0.0",
//     "ts-node": "~8.3.0",
//     "tslint": "~6.1.0",
//     "typescript": "~4.0.2"
//   }
// }
