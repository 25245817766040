import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './components/home/home.component'
import { ResultComponent } from './components/tables/result/result.component';
import { LoginComponent } from './components/login/login.component';
import { RegistroComponent } from './components/registro/registro.component';
import { AuthGuard } from './guards/auth.guard';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { HelpComponent } from './components/help/help.component';
import { CargarPedidoComponent } from './components/help/cargar-pedido/cargar-pedido.component';
import { LoadShipmentComponent } from './components/load-shipment/load-shipment.component';
import { SemaphoreComponent } from './components/semaphore/semaphore.component';
import { MapeComponent } from './components/mape/mape.component';
import { ForecastComponent } from './components/forecast/forecast.component';
import { ConfigPedidosComponent } from './components/config-pedidos/config-pedidos.component';
import { FactorComponent } from './components/import-factor/factor.component';

const routes: Routes = [
 {path: 'home',component: HomeComponent, canActivate: [AuthGuard]},
 {path: 'tables/result',component: ResultComponent, canActivate: [AuthGuard]},
 {path: 'login',component: LoginComponent},
 {path: 'forgotPassword', component: RecoverPasswordComponent},
 {path: 'help', component: HelpComponent, canActivate: [AuthGuard]},
 {path: 'help/cargarPedidos', component: CargarPedidoComponent, canActivate: [AuthGuard]},
 {path: 'cargarPedidos', component: LoadShipmentComponent, canActivate: [AuthGuard]},
 {path: 'semaphore', component: SemaphoreComponent, canActivate: [AuthGuard]},
 {path: 'mape', component: MapeComponent, canActivate: [AuthGuard]},
 {path: 'forecast', component: ForecastComponent, canActivate: [AuthGuard]},
 {path: 'config', component: ConfigPedidosComponent, canActivate: [AuthGuard]},
 {path: 'factor', component: FactorComponent, canActivate: [AuthGuard]},

 {path: 'register',component: RegistroComponent},
 {path: ' ', pathMatch: 'full', redirectTo: 'home'},
 {path: '**', pathMatch: 'full', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
