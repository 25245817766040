<div class="bg-light border-right" id="sidebar-wrapper">
    <div class="sidebar-heading"><a href="https://www.herramientasinformaticas.com/"><img class="center-block" src="assets/img/hi.png" height="32px" width="64px"></a></div>
    <div class="list-group list-group-flush">
      <a *ngIf="!isLogged" routerLink="login" class="list-group-item list-group-item-action" routerLinkActive="active">Iniciar sesión</a>
      <a *ngIf="!isLogged" routerLink="register" class="list-group-item list-group-item-action" routerLinkActive="active">Registrar</a>
      <a *ngIf="!isLogged" routerLink="forgotPassword" class="list-group-item list-group-item-action" routerLinkActive="active">Recuperar contraseña</a>
      <a *ngIf="isLogged" routerLink="home" class="list-group-item list-group-item-action" routerLinkActive="active">Inicio</a>
      <a *ngIf="isLogged" routerLink="tables/result" class="list-group-item list-group-item-action" routerLinkActive="active">Reporte de pedidos</a>
      <a *ngIf="isLogged" routerLink="semaphore" class="list-group-item list-group-item-action" >Semáforo de inventario</a>
      <a *ngIf="isLogged" routerLink="mape" class="list-group-item list-group-item-action" >Real vs Forecast</a>
      <a *ngIf="isLogged" routerLink="cargarPedidos" class="list-group-item list-group-item-action" >Carga de pedidos</a>
      <a *ngIf="isLogged" routerLink="forecast" class="list-group-item list-group-item-action" >Forecast BRP</a>
      <a *ngIf="isLogged" routerLink="config" class="list-group-item list-group-item-action" >Config. intérprete de pedidos</a>
      <a *ngIf="isLogged" routerLink="factor" class="list-group-item list-group-item-action" >Factor de importación</a>

      <a *ngIf="isLogged" routerLink="help" class="list-group-item list-group-item-action" routerLinkActive="active">Ayuda</a>
    
    </div>

  </div>