<div class="container-fluid">
  <div *ngIf="showFilter">
    <h3><img src="assets/img/embudo.png" width="32px" height="32px">Filtros</h3>
    <div class="row">
        
        <div class="col">
            
            <div class="form-check">
                <input [(ngModel)]="this.headers.cardCode" class="form-check-input" type="checkbox" value="" id="cardCode">
                <label class="form-check-label" for="cardCode">
                  *Código
                </label>
                <input #cardcode (keyup)="setByBars('code',cardcode.value)" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por código" aria-label="Buscar por código">
                <br>
                <input [(ngModel)]="this.headers.cardName" class="form-check-input" type="checkbox" value="" id="cardName">
                <label class="form-check-label" for="cardName">
                  *Cliente
                </label>
                <input #name (keyup)="setByBars('name',name.value)" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por nombre" aria-label="Buscar por nombre">
                <br>
                <input [(ngModel)]="this.headers.groupCode" class="form-check-input" type="checkbox" value="" id="groupCode">
                <label class="form-check-label" for="groupCode">
                  *Código de grupo
                </label>
                <input #groupcode (keyup)="setByBars('group_code',groupcode.value)" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por código de gpo." aria-label="Buscar por código de gpo.">
                <br>
                <input [(ngModel)]="this.headers.groupName" class="form-check-input" type="checkbox" value="" id="groupName">
                <label class="form-check-label" for="group_name">
                  *Grupo
                </label>
                <input #groupname (keyup)="setByBars('group_name',groupname.value)" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por nombre de gpo." aria-label="Buscar por nombre de gpo.">
        </div>
        
    </div>
    <div class="col">
        <div class="form-check">
            <input [(ngModel)]="this.headers.docEntry" class="form-check-input" type="checkbox" value="" id="docEntry">
            <label class="form-check-label" for="docEntry">
              Pedido
            </label>
            <input #document (keyup)="setByBars('document',document.value)" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por entrada." aria-label="Buscar por entrada.">
            <br>
            <input [(ngModel)]="this.headers.docTotal" class="form-check-input" type="checkbox" value="" id="docTotal">
            <label class="form-check-label" for="docTotal">
              Total MN
            </label>
            <br>
            <input [(ngModel)]="this.headers.docTotalFC" class="form-check-input" type="checkbox" value="" id="docTotalFC">
            <label class="form-check-label" for="docTotalFC">
              Total ME
            </label>
            <br>
            <input [(ngModel)]="this.headers.docRate" class="form-check-input" type="checkbox" value="" id="docRate">
            <label class="form-check-label" for="docRate">
              Tipo de cambio
            </label>
            <br>
            <input [(ngModel)]="filterByDate" class="form-check-input" type="checkbox" value="" id="filterBydate">
            <label class="form-check-label" for="filterbyDate">
              Filtrar por fecha
            </label>
            <br>
            <div *ngIf="filterByDate">
            <label>Desde: </label> <input [(ngModel)]="startTime" type="date">
            <br>
            <label>Hasta: </label> <input [(ngModel)]="endTime" type="date">
          </div>
            <br>
            <input [(ngModel)]="filterBycurr" class="form-check-input" type="checkbox" value="" id="filterBycurr">
            <label class="form-check-label" for="filterBycurr">
              Filtrar por moneda
            </label>
            <div *ngIf="filterBycurr">
              <select id="currency" (change)="selectedDiv=$event.target.value" class="form-control" value=''>
                <option hidden selected>Divisa</option>
                <option>MN</option>
                <option>USD</option>
                <option>EUR</option>
              </select>
            </div>
            <br>
    <button (click)="csvButton()" type="button" class="btn btn-success">Exportar CSV</button>
    <button (click)="button2status()" class="btn btn-warning">Mostrar/Ocultar</button>   
    <button (click)="reload()" class="btn btn-primary">Recargar</button>
    </div>
    </div>
    <div class="col">
            <input [(ngModel)]="this.headers.document_date" class="form-check-input" type="checkbox" value="" id="docDate1">
            <label class="form-check-label" for="docDate1">
              Fecha pedido
            </label>
            <br>
            <input [(ngModel)]="this.headers.required_date" class="form-check-input" type="checkbox" value="" id="docDate2">
            <label class="form-check-label" for="docDate2">
              Fecha entrega
            </label>
            <br>
            <input [(ngModel)]="this.headers.grossProfit" class="form-check-input" type="checkbox" value="" id="grosProfit">
            <label class="form-check-label" for="grosProfit">
              Margen MN
            </label>
            <br>
            <input [(ngModel)]="this.headers.margin" class="form-check-input" type="checkbox" value="" id="margin">
            <label class="form-check-label" for="margin">
              Margen %
            </label>
            <br>
            <input [(ngModel)]="this.headers.grossProfFC" class="form-check-input" type="checkbox" value="" id="grossProfFC">
            <label class="form-check-label" for="grossProfFC">
              Margen ME
            </label>     
    </div>
    <div class="col">
        <div class="form-check">
            
            <input [(ngModel)]="this.headers.ordrDocCur" class="form-check-input" type="checkbox" value="" id="ordrDocCur">
            <label class="form-check-label" for="ordrDocCur">
              Moneda 
            </label>
            <br>
            <!-- Cabeceras -->
        <div class="custom-control custom-switch">
        <input (change)="consolidar(6)" [(ngModel)]="consolidaded[6]" type="checkbox" class="custom-control-input" id="customSwitch" checked>
        <label *ngIf="!consolidaded[6]" class="custom-control-label" for="customSwitch"><b>Detalles del pedido</b></label>
        <label *ngIf="consolidaded[6]" class="custom-control-label" for="customSwitch"><b>Cabecera del pedido</b></label>
        </div>
        <div class="custom-control custom-switch">
          <input (change)="consolidar(4)" [(ngModel)]="consolidaded[4]" type="checkbox" class="custom-control-input" id="customSwitch6" checked>
          <label class="custom-control-label" for="customSwitch6"><b>Consolidado</b> por Grupo de Cliente</label>
          </div> 
        <div class="custom-control custom-switch">
          <input (change)="consolidar(1)" [(ngModel)]="consolidaded[1]" type="checkbox" class="custom-control-input" id="customSwitch3" checked>
          <label class="custom-control-label" for="customSwitch3"><b>Consolidado</b> por Cliente</label>
          </div>
          <div class="custom-control custom-switch">
            <input (change)="consolidar(2)" [(ngModel)]="consolidaded[2]" type="checkbox" class="custom-control-input" id="customSwitch4" checked>
            <label class="custom-control-label" for="customSwitch4"><b>Consolidado</b> por Grupo de Producto</label>
          </div>
          <div class="custom-control custom-switch">
            <input (change)="consolidar(3)" [(ngModel)]="consolidaded[3]" type="checkbox" class="custom-control-input" id="customSwitch5" checked>
            <label class="custom-control-label" for="customSwitch5"><b>Consolidado</b> por Producto</label>
            </div>
               
    </div>
    </div>
        <div class="col">
        <input [(ngModel)]="this.headers.ship_date" class="form-check-input" type="checkbox" value="" id="ship_date">
        <label class="form-check-label" for="ship_date">
        Fecha de entrega (Detalle)
        </label>
        <br>
        <input [(ngModel)]="this.headers.grssProfit" class="form-check-input" type="checkbox" value="" id="grssProf">
        <label class="form-check-label" for="grssProf">
        Margen MN (Detalle)
        </label>
        <br>
        <input [(ngModel)]="this.headers.marginLine" class="form-check-input" type="checkbox" value="" id="marginLine">
        <label class="form-check-label" for="marginLine">
         Margen % (Detalle)
        </label>
        <br>
        <input [(ngModel)]="this.headers.totalFrgn" class="form-check-input" type="checkbox" value="" id="totalFrgn">
        <label class="form-check-label" for="totalFrgn">
        Total ME (Detalle)
        </label>
        <br>
        <input [(ngModel)]="this.headers.grssProfitFC" class="form-check-input" type="checkbox" value="" id="grssProfFC">
        <label class="form-check-label" for="grssProfFC">
         Margen ME (Detalle)
        </label>
        <br>
            <input [(ngModel)]="this.headers.lineTotal" class="form-check-input" type="checkbox" value="" id="lineTotal">
            <label class="form-check-label" for="lineTotal">
              Total MN (Detalle)
            </label>
      </div>
    <div class="col">
        <div class="form-check">
            <input [(ngModel)]="this.headers.lineNum" class="form-check-input" type="checkbox" value="" id="lineNum">
            <label class="form-check-label" for="lineNum">
            # linea
            </label>
            <br>
            <input [(ngModel)]="this.headers.itemCode" class="form-check-input" type="checkbox" value="" id="itemCode">
            <label class="form-check-label" for="itemCode">
            *Código de producto
            </label>
            <input #itemCode (keyup)="setByBars('item_code',itemCode.value)" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por código de producto" aria-label="Buscar por código producto">
            <br>
            <input [(ngModel)]="this.headers.description" class="form-check-input" type="checkbox" value="" id="description">
            <label class="form-check-label" for="description">
              *Descripción de producto
            </label>
            <input #description (keyup)="setByBars('description',description.value)" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por descripción" aria-label="Buscar por descripción">
            <br>
            <input [(ngModel)]="this.headers.quantity" class="form-check-input" type="checkbox" value="" id="quantity">
            <label class="form-check-label" for="quantity">
              Cantidad
            </label>
            <br>
           
    </div>
    </div>
    <div class="col">
        <div class="form-check">
           <input [(ngModel)]="this.headers.price" class="form-check-input" type="checkbox" value="" id="price">
           <label class="form-check-label" for="price">
            Precio MN
           </label>
           <br>
           <input [(ngModel)]="this.headers.currency" class="form-check-input" type="checkbox" value="" id="cost">
           <label class="form-check-label" for="cost">
            Costo
            </label>
            <br>
            <input [(ngModel)]="this.headers.itmsGrpCod" class="form-check-input" type="checkbox" value="" id="itmsGrpCod">
            <label class="form-check-label" for="itmsGrpCode">
              *Código del grupo de productos
            </label>
            <input #ItmsGrpCod (keyup)="setByBars('item_group_code',ItmsGrpCod.value)" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por código de gpo. de productos" aria-label="Buscar por código de gpo. productos">
            <br>
            <input [(ngModel)]="this.headers.itmsGrpNam" class="form-check-input" type="checkbox" value="" id="itmGrpNam">
            <label class="form-check-label" for="itmGrpNam">
              *Grupo de productos
            </label>
            <input #ItmsGrpNam (keyup)="setByBars('item_group_name',ItmsGrpNam.value)" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por nombre de gpo. de productos" aria-label="Buscar por nombre de gpo. productos">
            <br>
            
    
    </div>
    </div>
    </div>
    </div>
    <br>
    <div class="custom-control custom-switch">
      <input [(ngModel)]="showFilter" type="checkbox" class="custom-control-input" id="customSwitch1" checked>
      <label *ngIf="!showFilter" class="custom-control-label" for="customSwitch1">Mostrar filtros</label>
      <label *ngIf="showFilter" class="custom-control-label" for="customSwitch1">Ocultar filtros</label>
      </div>
    <br>
    <div *ngIf="!JSONisLoaded" class="row text-center animated fadeIn">
      <div class="col">
          <i class="fa fa-sync fa-spin fa-5x"></i>
      </div>
    </div>  
    <div class="table-responsive" style="font-size: 13.1px;">
    <table *ngIf="JSONisLoaded" class="table table-sm table-hover" style="width:45%">
        <thead class="thead-per">
            <tr>
                <th *ngIf="this.headers.cardCode">Código</th>
                <th *ngIf="this.headers.cardName">Cliente</th>
                <th *ngIf="this.headers.groupCode">Código de grupo</th>
                <th *ngIf="this.headers.groupName">Grupo</th>
                <th *ngIf="this.headers.docEntry">Pedido</th>
                <th *ngIf="this.headers.document_date">Fecha pedido</th>
                <th *ngIf="this.headers.required_date">Fecha entrega</th>
                <th *ngIf="this.headers.docTotal">Total MN</th>
                <th *ngIf="this.headers.grossProfit">Margen MN</th>
                <th *ngIf="this.headers.margin">Margen %</th>
                <th *ngIf="this.headers.docTotalFC">Total ME</th>
                <th *ngIf="this.headers.grossProfFC">Margen ME</th>
                <th *ngIf="this.headers.docRate">Tipo de cambio</th>
                <th *ngIf="this.headers.ordrDocCur">Moneda</th>
                <th *ngIf="this.headers.lineNum"># línea</th>
                <th *ngIf="this.headers.ship_date">Fecha de entrega</th>
                <th *ngIf="this.headers.itemCode">Código de producto</th>
                <th *ngIf="this.headers.description">Descripción de producto</th>
                <th *ngIf="this.headers.quantity">Cantidad</th>
                <th *ngIf="this.headers.currency">Costo</th>
                <th *ngIf="this.headers.price">Precio MN</th>
                <th *ngIf="this.headers.itmsGrpCod">Cód. grupo de producto</th>
                <th *ngIf="this.headers.itmsGrpNam">Grupo de producto</th>
                <th *ngIf="this.headers.lineTotal">Total MN</th>
                <th *ngIf="this.headers.grssProfit">Margen MN</th>
                <th *ngIf="this.headers.marginLine">Margen %</th>
                <th *ngIf="this.headers.totalFrgn">Total ME</th>
                <th *ngIf="this.headers.grssProfitFC">Margen ME</th>
    
                
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let i of data | filter:idForSearch:consolidaded[6]:idForAttr:consolidaded[0]:filterByDate:startTime:endTime:filterBycurr:selectedDiv:consolidaded[5]; let in = index">
                <td *ngIf="this.headers.cardCode&&JSONisLoaded">{{i.code}}</td>
                <td *ngIf="this.headers.cardName&&JSONisLoaded">{{i.name}}</td>
                <td *ngIf="this.headers.groupCode&&JSONisLoaded">{{i.group_code}}</td>
                <td *ngIf="this.headers.groupName&&JSONisLoaded">{{i.group_name}}</td>
                <td *ngIf="this.headers.docEntry&&JSONisLoaded">{{i.document}}</td>
                <td *ngIf="this.headers.document_date&&JSONisLoaded">{{i.date }}</td>
                <td *ngIf="this.headers.required_date&&JSONisLoaded">{{i.due_date }}</td>
                <td *ngIf="this.headers.docTotal&&JSONisLoaded">{{i.total | currency:'':''}}</td>
                <td *ngIf="this.headers.grossProfit&&JSONisLoaded">{{i.profit | currency:'':''}}</td>
                <td *ngIf="this.headers.margin&&JSONisLoaded">{{i.margin * 100 | currency:'':'':'1.0-0'}}</td>
                <td *ngIf="this.headers.docTotalFC&&JSONisLoaded">{{i.totalFC | currency:'':''}}</td>
                <td *ngIf="this.headers.grossProfFC&&JSONisLoaded">{{i.profitFC | currency:'':''}}</td>
                <td *ngIf="this.headers.docRate&&JSONisLoaded">{{i.rate | currency:'':''}}</td>
                <td *ngIf="this.headers.ordrDocCur&&JSONisLoaded">{{i.currency}}</td>
                <td *ngIf="this.headers.lineNum&&JSONisLoaded">{{i.line}}</td>
                <td *ngIf="this.headers.ship_date&&JSONisLoaded">{{i.ship_date}}</td>
                <td *ngIf="this.headers.itemCode&&JSONisLoaded">{{i.item_code}}</td>
                <td *ngIf="this.headers.description&&JSONisLoaded">{{i.description}}</td>
                <td *ngIf="this.headers.quantity&&JSONisLoaded">{{i.quantity | currency:'':'': '1.0-0'}}</td>
                <td *ngIf="this.headers.currency&&JSONisLoaded">{{i.cost | currency:'':''}}</td>
                <td *ngIf="this.headers.price&&JSONisLoaded">{{i.price | currency:'':''}}</td>
                <td *ngIf="this.headers.itmsGrpCod&&JSONisLoaded">{{i.item_group_code}}</td>
                <td *ngIf="this.headers.itmsGrpNam&&JSONisLoaded">{{i.item_group_name}}</td>
                <td *ngIf="this.headers.lineTotal&&JSONisLoaded">{{i.line_total | currency:'':''}}</td>
                <td *ngIf="this.headers.grssProfit&&JSONisLoaded">{{i.profitLine | currency:'':''}}</td>
                <td *ngIf="this.headers.marginLine&&JSONisLoaded"> {{i.margin_line}}</td>
                <td *ngIf="this.headers.totalFrgn&&JSONisLoaded">{{i.total_line_FC | currency:'':''}}</td>
                <td *ngIf="this.headers.grssProfitFC&&JSONisLoaded">{{i.profit_line_FC | currency:'':''}}</td>
    
              </tr>
        </tbody>
        <thead style="background-color: gray;">
          <tr *ngFor="let j of data | filter:idForSearch:consolidaded[6]:idForAttr:consolidaded[0]:filterByDate:startTime:endTime:filterBycurr:selectedDiv:consolidaded[5]; let in = index">
            <th *ngIf="in == j.sumElements&&this.headers.cardCode&&JSONisLoaded">Totales: </th>
            <th *ngIf="in == j.sumElements&&this.headers.cardName&&JSONisLoaded">{{j.sumElements}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.groupCode&&JSONisLoaded">{{j.sumElements}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.groupName&&JSONisLoaded">{{j.sumElements}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.docEntry&&JSONisLoaded"></th>
            <th *ngIf="in == j.sumElements&&this.headers.document_date&&JSONisLoaded"></th>
            <th *ngIf="in == j.sumElements&&this.headers.required_date&&JSONisLoaded"></th>
            <th *ngIf="in == j.sumElements&&this.headers.docTotal&&JSONisLoaded">{{j.sumDocTotal | currency:'':''}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.grossProfit&&JSONisLoaded">{{j.sumGrossProf | currency:'':''}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.margin&&JSONisLoaded">{{j.sumHeadersMargin | percent}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.docTotalFC&&JSONisLoaded">{{j.sumDocTotalFC | currency:'':''}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.grossProfFC&&JSONisLoaded">{{j.sumGrossProfFC | currency:'':''}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.docRate&&JSONisLoaded"></th>
            <th *ngIf="in == j.sumElements&&this.headers.ordrDocCur&&JSONisLoaded"></th>
            <th *ngIf="in == j.sumElements&&this.headers.lineNum&&JSONisLoaded">Totales:</th>
            <th *ngIf="in == j.sumElements&&this.headers.ship_date&&JSONisLoaded">{{j.sumElements}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.itemCode&&JSONisLoaded"></th>
            <th *ngIf="in == j.sumElements&&this.headers.description&&JSONisLoaded"></th>
            <th *ngIf="in == j.sumElements&&this.headers.quantity&&JSONisLoaded">{{j.sumQuantity | currency:'':'': '1.0-0'}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.price&&JSONisLoaded"></th>
            <th *ngIf="in == j.sumElements&&this.headers.currency&&JSONisLoaded"></th>
            <th *ngIf="in == j.sumElements&&this.headers.itmsGrpCod&&JSONisLoaded"></th>
            <th *ngIf="in == j.sumElements&&this.headers.itmsGrpNam&&JSONisLoaded"></th>
            <th *ngIf="in == j.sumElements&&this.headers.lineTotal&&JSONisLoaded">{{j.sumLineTotal | currency:'':''}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.grssProfit&&JSONisLoaded">{{j.sumGrssProfit| currency:'':''}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.marginLine&&JSONisLoaded">{{j.sumDetailsMargin | percent}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.totalFrgn&&JSONisLoaded">{{j.sumTotalFC | currency:'':''}}</th>
            <th *ngIf="in == j.sumElements&&this.headers.grssProfitFC&&JSONisLoaded">{{j.sumGrssProfitFC | currency:'':''}}</th>
    
      </tr>
      </thead>
    </table>
    </div>
    <textarea *ngIf="JSONisLoaded" id="csv" style="visibility:hidden;">{{data | filter:idForSearch:consolidaded[6]:idForAttr:consolidaded[0]:filterByDate:startTime:endTime:filterBycurr:selectedDiv:consolidaded[5]| json}}</textarea>    
</div>