import { Component, OnInit } from '@angular/core';
import {Auth, selectInput} from 'aws-amplify';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: [
  ]
})
export class HomeComponent implements OnInit {

  constructor() {
    
   }
  name: string;
  lastname:string;
  phone:string;
  ngOnInit(): void {
    Auth.currentAuthenticatedUser().then((data => {
      console.log(data)
      this.name = data['attributes'].given_name;
      this.lastname = data['attributes'].family_name;
      this.phone = data['attributes'].phone_number;
      console.log("Hola");
    }),(error=>{
      console.log(error)
    })
    )
  }

}
