<div class="container-fluid">
    <div class="alert" style="display: none;" id="alert_zone">
        <h1>{{MSG_TEXT[0]}}</h1>
        {{MSG_TEXT[1]}}
        <br>
        {{MSG_TEXT[2]}}
    </div>
    <div *ngIf="false" class="custom-control custom-switch">
    <input [(ngModel)]="showFilter" type="checkbox" class="custom-control-input" id="customSwitch1" checked>
    <label class="custom-control-label" for="customSwitch1">{{showFilter ? 'Desactivar filtros' : 'Activar filtros'}}</label>
    </div>
    
    <!--Control de tabla-->
    <div *ngIf="!JSONisLoaded else loaded" class="row text-center animated fadeIn">
        <div class="col">
            <i class="fa fa-sync fa-spin fa-5x"></i>
        </div>
      </div>
    <ng-template #loaded>
        <div class="table-responsive">
            <table class="table table-hover">
                <thead>
                    <th>
                        <!--Columna vacía-->
                    </th>
                    <th *ngFor="let j of HEADERS_T.slice(1,HEADERS_T.length)"><input (click)="updateSend($event.target.checked,$event.target.value);" [disabled]="DONE===true" type="checkbox" class="form-control" [value]="j"></th>
                </thead>
                <thead class="thead-per">
                    <th *ngFor="let j of HEADERS_T"><div [style.text-align]="SCOPE==='month' && j!='code'?'center':''">{{j|forecastT}}</div></th>
                </thead>
                <tbody>
                    <tr *ngFor="let i of DATA; let in = first">
                        <th *ngFor="let j of HEADERS_T">
                            <div *ngIf="SCOPE==='week'" [style.text-align]="j === 'code'? 'left' : 'right'">{{ j!='code' ? (i[j] | number :'1.0':'en-US')  : i[j]}}</div>
                            <div *ngIf="SCOPE==='month'" [style.text-align]="j === 'code'?'left':'center'">{{ j!='code' ? (i[j] | number:'1.0':'en-US') : i[j]}}</div>
                        </th>
                    </tr>
                </tbody>
                <tbody style="background-color: gray!important; color: white!important;">
                    <tr>
                        <th>Totales:</th>
                        <th *ngFor="let i of DATA|forecast:HEADERS_T">{{i | number :'1.0':'en-US'}}</th>
                    </tr>
                </tbody>
            </table>
            </div>
            <br>
            <button class="btn btn-success" [disabled]="!SEND_HEADERS || SEND_HEADERS.length === 0 || DONE == true" (click)="onSend();">Crear pedido</button>
    </ng-template>
</div>