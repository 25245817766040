import { convertPropertyBinding } from '@angular/compiler/src/compiler_util/expression_converter';
import * as FileSaver from 'file-saver';
import { json2csv } from 'json-2-csv';
export class createCSV{
    headers:any;
    constructor(headers:any){
        this.headers=headers;
    }
    createCSV(data){
      var json = data
      var fields = Object.keys(json[0])
      var replacer = function(key, value) { return value === null ? '' : value } 
      var csv = json.map(function(row){
      return fields.map(function(fieldName){
      return JSON.stringify(row[fieldName], replacer)
    }).join(',')
    })
    csv.unshift(fields.join(',')) // add header column
    csv = csv.join('\r\n');
    return csv;
    }
    renameKey ( obj, oldKey, newKey ) {
      obj[newKey] = obj[oldKey];
      delete obj[oldKey];
    }
      traslateJSON(json):any{
      json.forEach(obj => this.renameKey (obj,'code','Codigo'));
      json.forEach(obj => this.renameKey (obj,'name','Nombre'));
      json.forEach(obj => this.renameKey (obj,'group_code','Código de grupo'));
      json.forEach(obj => this.renameKey (obj,'group_name','Nombre de grupo'));
      json.forEach(obj => this.renameKey (obj,'document','Pedido'));
      json.forEach(obj => this.renameKey (obj,'date','Fecha pedido'));
      json.forEach(obj => this.renameKey (obj,'due_date','Fecha de entrega'));
      json.forEach(obj => this.renameKey (obj,'total','Total MN'));
      json.forEach(obj => this.renameKey (obj,'profit','Margen MN'));
      json.forEach(obj => this.renameKey (obj,'margin','Margen %'));
      json.forEach(obj => this.renameKey (obj,'totalFC','Total ME'));
      json.forEach(obj => this.renameKey (obj,'profitFC','Margen ME'));
      json.forEach(obj => this.renameKey (obj,'rate','Tipo de cambio'));
      json.forEach(obj => this.renameKey (obj,'currency','Moneda'));
      json.forEach(obj => this.renameKey (obj,'line','# de linea'));
      json.forEach(obj => this.renameKey (obj,'ship_date','Fecha de entrega (d)'));
      json.forEach(obj => this.renameKey (obj,'item_code','Codigo de producto'));
      json.forEach(obj => this.renameKey (obj,'description','Descripción'));
      json.forEach(obj => this.renameKey (obj,'quantity','Cantidad'));
      json.forEach(obj => this.renameKey (obj,'cost','Costo'));
      json.forEach(obj => this.renameKey (obj,'price','Precio MN'));
      json.forEach(obj => this.renameKey (obj,'line_total','Total MN (d)'));
      json.forEach(obj => this.renameKey (obj,'profitLine','Margen MN (d)'));
      json.forEach(obj => this.renameKey (obj,'margin_line','Margen % (d)'));
      json.forEach(obj => this.renameKey (obj,'total_line_FC','Total ME (d)'));
      json.forEach(obj => this.renameKey (obj,'profit_line_FC','Margen ME (d)'));
      json.forEach(obj => this.renameKey (obj,'item_group_code','Cód. grupo de producto'));
      json.forEach(obj => this.renameKey (obj,'item_group_name','Grupo de producto'));
      return json;
      }
      csvButton(){
        let json = JSON.parse((<HTMLInputElement>document.getElementById("csv")).value);
        json = this.traslateJSON(json);
        for(const p in json){
          if(this.headers.cardCode==false){delete json[p]['Codigo'];}
          if(this.headers.cardName==false){delete json[p]['Nombre'];}
          if(this.headers.groupCode ==false){delete json[p]['Código de grupo'];}
          if(this.headers.groupName==false){delete json[p]['Nombre de grupo'];}
          if(this.headers.docEntry==false){delete json[p]['Pedido'];}
          if(this.headers.docTotal==false){delete json[p]['Total MN'];}
          if(this.headers.docTotalFC==false){delete json[p]['Total ME'];}
          if(this.headers.docRate==false){delete json[p]['Tipo de cambio'];}
          if(this.headers.ordrDocCur==false){delete json[p]['Moneda'];}
          if(this.headers.lineNum==false){delete json[p]['# de linea'];}
          if(this.headers.itemCode==false){delete json[p]['Codigo de producto'];}
          if(this.headers.description==false){delete json[p]['Descripción'];}
          if(this.headers.quantity==false){delete json[p]['Cantidad'];}
          if(this.headers.price==false){delete json[p]['Precio MN'];}
          if(this.headers.currency==false){delete json[p]['Costo'];}
          if(this.headers.lineTotal==false){delete json[p]['Total MN (d)'];}
          if(this.headers.itmsGrpCod==false){delete json[p]['Cód. grupo de producto'];}
          if(this.headers.itmsGrpNam==false){delete json[p]['Grupo de producto'];}
          if(this.headers.document_date==false){delete json[p]['Fecha pedido'];}
          if(this.headers.required_date==false){delete json[p]['Fecha de entrega'];}
          if(this.headers.margin==false){delete json[p]['Margen %'];}
          if(this.headers.grossProfit==false){delete json[p]['Margen MN'];}
          if(this.headers.grossProfFC==false){delete json[p]['Margen ME'];}
          if(this.headers.ship_date==false){delete json[p]['Fecha de entrega (d)'];}
          if(this.headers.grssProfit==false){delete json[p]['Margen MN (d)'];}
          if(this.headers.marginLine==false){delete json[p]['Margen % (d)'];}
          if(this.headers.totalFrgn==false){delete json[p]['Total ME (d)'];}
          if(this.headers.grssProfitFC==false){delete json[p]['Margen ME (d)'];}
        }
        const data: Blob = new Blob([this.createCSV(json)], { type: 'text/plain;charset=utf-8' });
        const fileName:string = 'Reporte Bollhoff '+new Date().getFullYear()+'-'+new Date().getDay()+'-'+new Date().getMonth()+':'+new Date().getHours()+'.'+new Date().getMinutes()+'.'+new Date().getSeconds()+'.csv'
        FileSaver.saveAs(data, fileName);
      }
}