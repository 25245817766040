import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Auth} from 'aws-amplify';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLogged:boolean;
  constructor(private r:Router,private h:HttpClient) { 
    
  }
  getLogin():boolean{
    return this.isLogged
  }

  async logout(){
    console.log('Cerrando sesión')
    try {
       await Auth.signOut({global: true})
       this.r.navigate(['login']);
       this.isLogged == false;
       localStorage.clear();
       this.r.navigateByUrl('/login').then(()=>{
         window.location.reload()
       })
    }catch(error){
      console.log(error)
    }
  }
    saveToken (token:string,pool:string){
    localStorage.setItem('pool',pool);
    localStorage.setItem('token',token);
    let hoy = new Date();
    hoy.setSeconds(3600);
    localStorage.setItem('expires',hoy.getTime().toString());

  }
  async forgotPassword(username:string){
    return Auth.forgotPassword(username);
  }
  async recoverPassword(username:string,code:string,password:string){
    return Auth.forgotPasswordSubmit(username,code,password);
  }
  readToken():boolean{
    if(localStorage.getItem('token')){
       let data:any=localStorage.getItem('token');
       this.isLogged = true;
       const expira = Number(localStorage.getItem('expires'))
       const hoy = new Date();
       hoy.setTime(expira)
       if(hoy> new Date()){
       return true;
       }
       else {
        localStorage.clear();
        return false;  
       }
       
    }  
    else {
      localStorage.clear();
      this.isLogged = false; 
      return false; 
    }  
  }
  async register(email:string,password:string,name:string,lastname:string,phone:string){
      return Auth.signUp(
       {username: email,
        password: password,
        attributes: {
          email: email,
          given_name: name,
          family_name: lastname,
          phone_number: '+52'+phone.substr(0,10)
        }
      })
  }
 
  async login(email:string,password:string){
      return Auth.signIn(email.toString(),password.toString());
  }
  sendToken():any{
    return {
      token : localStorage.getItem('token'),
      expires : localStorage.getItem('expires'),
      pool : localStorage.getItem('pool')
    }
  }
  private _headers = new HttpHeaders().set('Content-Type', 'application/json');
  testAPI(header):any{
    const h = this._headers.append('authorizationToken',header)
    console.log(h)
    return this.h.get('https://w0c78d732h.execute-api.us-east-1.amazonaws.com/dev',{headers:h})
  }
}  
