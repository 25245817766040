<p>Ingresa al cliente OD e inicia sesión</p>
<img src="assets/img/help/cargarPedido/1.png">
<br><br><br>
<p>Aparece la ventana principal</p>
<img src="assets/img/help/cargarPedido/2.png">
<br><br><br>
<p>Selecciona el botón en la barra lateral -> Reportes</p>
<img src="assets/img/help/cargarPedido/3.png">
<br><br><br>
<p>Una vez en Reportes -> clic en Envíar</p>
<img src="assets/img/help/cargarPedido/4.png">
<br><br><br>
<p>Si el envío ha sido exitoso, aparecerá "Exitoso"</p>
<br><br><br>