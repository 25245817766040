<div class="container-fluid">
<div *ngIf="ERR_MSG" id="error_log" class="alert alert-danger" role="alert">
    <h2>Error:</h2>
    {{error_msg}}
</div>
<div *ngIf="DONE" id="success_log" class="alert alert-success" role="alert">
    <h2>Éxito:</h2>
    Archivo recibido y se inicia su procesamiento.
    {{finish_date}}
</div>
<h1>Cargar pedidos</h1>
<h3>Instrucciones:</h3>
Arrastre sus pedidos en PDF, XLS o XLSX (Excel), HTML o HTM para exportarlos al intérprete de pedidos.
<br><br><br>
<!--<h3>Agregue aquí su archivo:</h3>-->
<div class="row">
    <div class="col">
        <div class="dropzone">
            <input class="input_diag" type="file" #fileDropRef id="fileDropRef" multiple (change)="loadFiles($event.target.files);">
            <img src="" alt="">
            <h3>Arrastra y suelta el archivo</h3>
            <h3>o</h3>
            <label for="fileDropRef">Seleccionar archivo</label>
        </div>
    </div>
    <div class="col">
        <div id="displayed_files">
            <!--As table-->
            <table style="border: none;">
                <td *ngFor="let e of FILES_PROPERTIES; let i = index">
                    <p><img src="{{e | extension}}" id="fileImg" width="64px" heigth="64px"></p>
                    <small><b>Archivo: </b>{{e}}</small>
                    <br>
                    <small><b>Opciones:</b></small>
                    <br>
                    <button type="button" (click)="deleteElement(i)" class="btn btn-danger">Borrar</button> &nbsp;
                </td>
            </table>
        </div>
    </div>
    <div class="col">
        <button type="button" (click)="prepareFiles()" class="btn btn-primary btn-lg convert-button" id="c-button">Convertir</button>
        <br><br><br>
        <button type="button" class="btn btn-warning" (click)="loadReports()" style="padding-right: 5em;">Actualizar resultados</button>
        <div *ngIf="SHOW_TABLE">
            <br>
            <button style="width: 250px; height: 50px; text-align: left; border-color: #00aae4; color: #00aae4; background-color: white;" (click)="loadReports(); BTN_SELECTED=0;">Todos <img *ngIf="BTN_SELECTED==0" src="assets/img/selected.png" height="32px" width="32px"></button>
            <br>
            <div *ngFor="let i of FILE_LIST; let in = index">
                <button style="width: 250px; height: 50px; text-align: left; border-color: #00aae4; color: #00aae4; background-color: white;" (click)="loadReports(i);BTN_SELECTED=in+1;">{{i}}    <img *ngIf="BTN_SELECTED==in+1" src="assets/img/selected.png" height="32px" width="32px"></button> <button (click)="deleteOrder(i);" type="button" style="width: 50px; height: 50px;" class="btn btn-danger"><img src="assets/img/table/delete.png" width="16px" height="16px"></button>
            </div>
        </div>
    </div>
</div>
<br>
<br><br><br>
<div id="results_area" *ngIf="SHOW_TABLE">
    <table class="table table-sm table-hover">
        <thead class="thead-per">
            <tr>
                <th>Índice</th>
                <th>Código</th>
                <th>Nombre</th>
                <th>Referencia</th>
                <!--<th>Nota</th>-->
                <th>Dirección</th>
                <th>Moneda</th>
                <!--<th>Fecha de pedido</th>-->
                <th>Fecha de entrega</th>
                <th>Semana</th>
                <!--<th>Descargar</th>-->
                <th>Orden SAP</th>
                <th>Num. Orden</th>
                <th>Detalles</th>
            </tr>
        </thead>
        <tbody>
            <tr [ngClass]="{'table-warning':((in+1)%2==0)}" [id]="'nested_'+in" *ngFor="let i of REPORTS_TABLE; let in = index">
                <td>{{in+1}}</td>
                <td style="width: 6%;"><input type="text" class="form-control" [(ngModel)]="i.cardCode"></td>
                <td style="width: 15%;"><input type="text" class="form-control" [(ngModel)]="i.customer"></td>
                <td style="width: 10%;"><input type="text" class="form-control" [(ngModel)]="i.refOrder"></td>
                <!--<td style="width: 10%;"><input type="text" class="form-control" [(ngModel)]="i.note"></td>-->
                <td style="width: 9%;"><input type="text" class="form-control" [(ngModel)]="i.address"></td>
                <td style="width: 3%;"><input type="text" class="form-control" [(ngModel)]="i.currency"></td>
                <!--<td><input type="date" class="form-control" [(ngModel)]="i.shippingDate"></td>-->
                <td><input type="date" class="form-control" [(ngModel)]="i.deliveryDate"></td>
                <td style="width: 3%;"><input type="text" class="form-control" [(ngModel)]="i.deliveryWeek"></td>
                <!--<td><select [(ngModel)]="i.downloadable" class="form-control">
                    <option>true</option>
                    <option>false</option>
                </select></td>-->
                <td><input type="number" class="form-control" [(ngModel)]="i.docEntry"></td>
                <td><input type="text" class="form-control" [(ngModel)]="i.customerOrderNumber"></td>
                <td>
                    <button type="button" class="btn btn-warning" (click)="setDetails(in);DETAILS_ID = in; printOnNest(in);"><img src="/assets/img/table/details.png" width="24px" height="24px"></button>
                    <button type="button" (click)="deleteRecord(i.id);" class="btn btn-danger"><img src="/assets/img/table/delete.png" width="24px" height="24px"></button>
                    <button type="button" (click)="saveRecord(in,i.id)" class="btn btn-success"><img src="/assets/img/table/save.png" width="24px" height="24px"></button>
                </td>
            </tr>
        </tbody>
    </table>
    <br><br>
</div>

<swal #details_modal title="Detalles:">
    <!-- This form will be displayed as the alert main content
         Targets the alert's main content zone by default -->
    <form *swalPortal>
      <div *ngFor="let j of DETAILS_REPORT; let jn=index" (confirm)="updateDetails();">
          <h4>{{jn}}</h4>
          <p># Item:</p>
          <input type="text" class="form-control" [(ngModel)]="j.ItemNo" name="num_item">
          <p>Descripcion:</p>
          <textarea class="form-control" [(ngModel)]="j.descripcion" name="desp"></textarea>
          <div class="row">
              <div class="col">
                <p>Precio:</p>
                <input type="number" class="form-control" [(ngModel)]="j.price" name="price">
              </div>
              <div class="col">
                <p>IVA:</p>
                <input type="number" class="form-control" [(ngModel)]="j.iva" name="iva">
              </div>
              <div class="col">
                <p>Cantidad:</p>
                <input type="number" class="form-control" [(ngModel)]="j.quantity" name="quantity">
              </div>
          </div>
          <div class="row">
            <div class="col">
              <p>Total:</p>
              <input type="number" class="form-control" [(ngModel)]="j.total" name="total">
            </div>
            <div class="col">
              <p>Subtotal:</p>
              <input type="number" class="form-control" [(ngModel)]="j.subtotal" name="subtotal">
            </div>
        </div>
          <hr>
      </div>
    </form>
  
    <!-- This targets the confirm button's inner content
         Notice the usage of ng-container to avoid creating an useless DOM element inside the button -->
    <ng-container *swalPortal="swalTargets.confirmButton">
        Aceptar
    </ng-container>
  </swal>
  <div id="snackbar">{{SNACKBAR_MSG}}</div>
  </div>