import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {
  user: UserModel;
  signUpError:boolean=false;
  error_message:string='';
  constructor(private router:Router,private auth:AuthService) {
    this.user = new UserModel();
   }

  ngOnInit(): void {
  }
  register(form:NgForm){
    if(form.invalid){return;}
    this.auth.register(this.user.email,this.user.password
    ,this.user.name,this.user.lastname,this.user.phone).then((data)=>{
      alert('Registro exitoso, verifica tu email');
      this.router.navigate(['login']);
    },(err)=>{
      this.signUpError=true;
      this.error_message=err['message'];
      console.log('Error al registrar\n');
      console.error(err);

    });

  }
}
