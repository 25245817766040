import { HttpClient, HttpHeaders} from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {API} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {
  headers:any = {
    cardCode: true,
    cardName: true,
    groupCode: true,
    groupName: true,
    docEntry: true,
    docTotal: true,
    docTotalFC: true,
    docRate: true,
    ordrDocCur: true,
    jrnlMemo: true,
    lineNum: true,
    itemCode: true,
    description: true,
    quantity: true,
    price: true,
    currency: true,
    lineTotal: true,
    itmsGrpCod: true,
    itmsGrpNam: true,
    searchBar: true,
    document_date:true,
    required_date:true,
    margin:true,
    grossProfit:true,
    grossProfFC:true,
    marginFC:true,
    ship_date:true,
    grssProfit:true,
    marginLine:true,
    totalFrgn:true,
    grssProfitFC:true,
    marginLineFC:true,
  }
  constructor(private http:HttpClient) { }
  getData(){
    return this.http.get(`${API}/reportOrders`,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  })
    .pipe( map( data => data['data']));
  }
 getHeaders():any{
    return this.headers;
  }
  getReports(f){
    return this.http.get(`${API}/orders?filter=${f}`,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  });
  }
  eraseReport(id:string, body:any){
    return this.http.delete(`${API}/orders/${id}`,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  });
  }
  eraseFileReport(filename:string,body:any){
    return this.http.delete(`${API}/orders/file/${filename}`,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  });
  }
  updateReport(id:string, body:any){
    return this.http.put(`${API}/orders/${id}`,body,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  });
  }
  getReportsFiles(){
    return this.http.get(`${API}/orders?filter=LISTAR-ARCHIVOS`,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  });
  }
  postProduct(body:any){
    return this.http.post(`${API}/indexcp`,body,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  });
  }
  getProducts(){
    return this.http.get(`${API}/indexcp`,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  });
  }
  deleteProduct(index:any){
    return this.http.delete(`${API}/indexcp/${index}`,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  });
  }
}
