import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'FilterFactor'
})
export class FilterFactor implements PipeTransform {
  DATA:any[] = [];
  obtieneSuma(key:string):number{

    if (key==="total"){
      return this.DATA.length;
    }
    else if(this.DATA.length>0){
      const data = this.DATA.map((x)=>this.convertToNum(x[key])).reduce((prev,next) => prev + next);
      return data;
    } else {
      return 0;
    }
  }

  obtieneSumaporciento(key:string):number{
    if (key==="total"){
      return this.DATA.length;
    }
    else if(this.DATA.length>0){
      const data01 = this.DATA.map((x)=>this.convertToNum(x["VALOR_EN_MN"])).reduce((prev,next) => prev + next);
      const data02 = this.DATA.map((x)=>this.convertToNum(x["TOTAL_GASTOS"])).reduce((prev,next) => prev + next);
      const data= (data02)/(data01);

      return data;
    } else {
      return 0;
    }
  }
  
  convertToNum(val:any):number{
    if(isNaN(val)){ val = Number(val.replace(/\%|\$|\,/g,"")) } else if (val===''){val=0}
    return val
  }
  transform(data: any, ...args:any[]): any {
    this.DATA = data;
    let post = [];
    console.log(data);
    if(args[0]==true){
      switch(args[1]){
        case 1:
          if((data?.filter(x=>x['codigo'])).length==0){
            post = data?.filter(x=>x['PROVEEDOR'].toLowerCase().startsWith(args[2].toLowerCase()));
            // console.log(post);
            post = post?.filter(x=>x['FECHA'].toLowerCase().startsWith(args[3].toLowerCase()));

            this.DATA = post;
  
            
          }else{

          post = data?.filter(x=>x['codigo'].toLowerCase().startsWith(args[2].toLowerCase()));
          post = post?.filter(x=>x['descripcion']?.toLowerCase()?.startsWith(args[3]?.toLowerCase()));
          post = post?.filter(x=>x['grupo'].toLowerCase().startsWith(args[4].toLowerCase()));
          post = post?.filter(x=>x['proveedor'].toLowerCase().startsWith(args[5].toLowerCase()));
          if(args[6]!=""){
            post = post?.filter(x=>x['semaforo'] == args[6])
          }
          this.DATA = post;

          }

         
          
        break;
        case 2:
          post = data.filter(x=>x['Codigo'].toLowerCase().startsWith(args[2].toLowerCase()));
          post = post.filter(x=>x['Descripcion'].toLowerCase().startsWith(args[3].toLowerCase()));
          post = post.filter(x=>x['Grupo'].toLowerCase().startsWith(args[4].toLowerCase()));
          if(args[5]!=""){
            post = post.filter(x=>x['ABC'] == args[5])
          }
          this.DATA = post;
        break;
      }
      if(args[7]==="sum"){return this.obtieneSuma(args[8]).toFixed(2);}

      else if(args[7]==="pocientosum"){return this.obtieneSumaporciento(args[8]);
      }
      
      else
      {return this.DATA;}
    } 
      else {
        if(args[7]==="sum"){return this.obtieneSuma(args[8]).toFixed(2);}
        else if(args[7]==="pocientosum"){return this.obtieneSumaporciento(args[8]);
        }
        
        else
        {return this.DATA;}
    }
  }

}
