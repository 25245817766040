import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'forecastT'
})
export class ForecastTPipe implements PipeTransform {

  transform(text:string): unknown {
    switch(text){
      case "code" : return "Código";
      default : return text;
    }
  }

}
