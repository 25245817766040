<nav id="barraNav" class="navbar navbar-light" style="background-color: #3367D6;">
    <!-- Navbar content -->
    <div class="pos-f-t">
      <button class="navbar-toggler" type="button" (click)="sidebarControl()" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
        <nav class="navbar navbar-light">
        </nav>
      </div>
      <div style="float: left;"><img src="assets/img/bollhoff.png" width="50px" heigth="25px"></div>
      <h1 style="color: black;">Portal de pedidos Bollhoff</h1>
      <form class="form-inline">
        <img *ngIf="isLogged" src="/assets/img/user.png" width="32px" height="32px">
        <p>&nbsp;</p>
        <button (click)="cerrarSesion()" *ngIf="isLogged" type="button" class="btn btn-danger">Logout</button>
        <button  (click)="test()" *ngIf="false" type="button" class="btn btn-primary">Test</button>
      </form>
  </nav>
  <div class="container-fluid" *ngIf="getURL()=='home'">Inicio</div>
  <div class="container-fluid" *ngIf="getURL()!='home' && getURL()!='login'"><a href="/home">Inicio</a><img src="assets/img/Div.png" width="16px" height="16px">{{getURL() | urlName}}</div>
  
  <br>