import { Directive, EventEmitter, Output, HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {

  @Output() fileDropped = new EventEmitter<any>();

  @HostListener('dragover',['$event']) dragOver(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave',['$event']) public dragLeave(event){
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop',['$event']) public drop(event){
    const file = event.dataTransfer.files;
    event.preventDefault();
    event.stopPropagation();
    if(file.length>0){
      this.fileDropped.emit(file);
  
    }   
  }
  constructor() { }

}
