import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import Amplify, { Auth } from 'aws-amplify';
import {HttpClientModule} from '@angular/common/http'
import {enableProdMode} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HomeComponent } from './components/home/home.component';
import { ResultComponent } from './components/tables/result/result.component';
import { LoginComponent } from './components/login/login.component';
import { RegistroComponent } from './components/registro/registro.component';
import { FilterPipe } from './pipes/filter.pipe';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { HelpComponent } from './components/help/help.component';
import { CargarPedidoComponent } from './components/help/cargar-pedido/cargar-pedido.component';
import { LoadShipmentComponent } from './components/load-shipment/load-shipment.component';
import { DragAndDropDirective } from './components/load-shipment/drag-and-drop.directive';
import { ExtensionPipe } from './pipes/extension.pipe';
import { SemaphoreComponent } from './components/semaphore/semaphore.component';
import { SemaphorePipe } from './pipes/semaphore.pipe';
import { FactorPipe } from './pipes/import-factor.pipe';
import { MapeComponent } from './components/mape/mape.component';
import { RemoveDecimalsPipe } from './pipes/remove-decimals.pipe';
import { Filtradorv3Pipe } from './pipes/test.pipe';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ForecastComponent } from './components/forecast/forecast.component';
import { ForecastPipe } from './pipes/forecast.pipe';
import { ForecastTPipe } from './pipes/forecast-t.pipe';
import { ConfigPedidosComponent } from './components/config-pedidos/config-pedidos.component';
import { FactorComponent } from './components/import-factor/factor.component';
import { UrlNamePipe } from './pipes/url-name.pipe';
import { AutocompleteOffDirective } from './autocomplete-off.directive';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { Filtradorv3Pipe02} from './pipes/test.pipe01';
import { FilterPipeC } from './pipes/test.pipeC';
import { FilterPipeN } from './pipes/test.pipeN';
import { FilterPipeS } from './pipes/test.pipeS';
import {FilterFactor} from './pipes/test.pipeFactorNew'
import { FilterPipe003 } from './pipes/test.pipe2';

enableProdMode();
Amplify.configure({
    Auth:{
      mandatorySignIn:true,
      region: 'us-east-1',
      userPoolId: 'us-east-1_iSp0Zrqc5',
      userPoolWebClientId: '70np53np8lhd059dt5bg7b5ic8',
      authenticationFlowType:'USER_PASSWORD_AUTH'
    }
  });
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    HomeComponent,
    ResultComponent,
    LoginComponent,
    RegistroComponent,
    FilterPipe,
    RecoverPasswordComponent,
    HelpComponent,
    CargarPedidoComponent,
    LoadShipmentComponent,
    DragAndDropDirective,
    ExtensionPipe,
    SemaphoreComponent,
    SemaphorePipe,
    MapeComponent,
    RemoveDecimalsPipe,
    Filtradorv3Pipe,
    Filtradorv3Pipe02,
    ForecastComponent,
    ForecastPipe,
    ForecastTPipe,
    ConfigPedidosComponent,
    UrlNamePipe, 
    AutocompleteOffDirective,
    SanitizePipe,
    FactorComponent,
    FactorPipe,
    FilterPipeC,
    FilterPipeN,
    FilterPipeS,
    FilterFactor,
    FilterPipe003
  ],
  imports: [
    FormsModule,
    BrowserModule, 
    AppRoutingModule,
    HttpClientModule,
    SweetAlert2Module.forRoot()
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }