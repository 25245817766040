import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlName'
})
export class UrlNamePipe implements PipeTransform {

  transform(name:string): string {
    switch(name){
      case 'result' : return 'Reporte de pedidos'; break;
      case 'semaphore' : return 'Semáforo de inventario'; break;
      case 'mape' : return 'Real vs Forecast'; break;
      case 'forecast': return 'Forecast BRP'; break;
      case 'cargarPedidos': return 'Cargar pedidos'; break;
      case 'config': return 'Configuración intérprete de pedidos'; break;
      case 'help': return 'Ayuda'; break;
      default : return name; break;
    }
  }

}
