import * as FileSaver from 'file-saver';
export class createSemaphoreCSV{
    HEADERS:boolean[];

    createCSV(data){
        var json = data
        var fields = Object.keys(json[0])
        var replacer = function(key, value) { return value === null ? '' : value } 
        var csv = json.map(function(row){
        return fields.map(function(fieldName){
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
      })
      csv.unshift(fields.join(',')) // add header column
      csv = csv.join('\r\n');
      return csv;
      }
    filterCSV(json):any{
        for(const i in json){
            if(this.HEADERS[0]==true){delete json[i]['cnt'];}
            if(this.HEADERS[1]==true){delete json[i]['codeProv'];}
            if(this.HEADERS[2]==true){delete json[i]['comentarios'];}
            if(this.HEADERS[3]==true){delete json[i]['proveedor'];}
            if(this.HEADERS[4]==true){delete json[i]['costo'];}
            if(this.HEADERS[5]==true){delete json[i]['costoTotal'];}
            if(this.HEADERS[6]==true){delete json[i]['docEntry'];}
            if(this.HEADERS[7]==true){delete json[i]['minimo'];}
            if(this.HEADERS[8]==true){delete json[i]['minimo_valor'];}
            if(this.HEADERS[9]==true){delete json[i]['pedidos_proveedores'];}
            if(this.HEADERS[10]==true){delete json[i]['pedidos_clientes'];}
            if(this.HEADERS[11]==true){delete json[i]['porcentaje'];}
            if(this.HEADERS[12]==true){delete json[i]['semaforo'];}
        }
    return json
    }



    exportCSV():void{
        let json = JSON.parse((<HTMLInputElement>document.getElementById("hidden_textarea")).value);
        json = this.filterCSV(json);
        const data: Blob = new Blob([this.createCSV(json)], { type: 'text/plain;charset=utf-8' });
        const fileName:string = 'Semaforo Bollhoff '+new Date().getFullYear()+'-'+new Date().getDay()+'-'+new Date().getMonth()+':'+new Date().getHours()+'.'+new Date().getMinutes()+'.'+new Date().getSeconds()+'.csv'
        FileSaver.saveAs(data, fileName);

    }
    constructor(headers:boolean[]){
        this.HEADERS = headers;

    }
}