import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'semaphore'
})
export class SemaphorePipe implements PipeTransform {
  DATA:any = [];
  sumObj(key:string):number{
    if(this.DATA.length>0){
      const data = this.DATA.map((x)=>this.convertToNum(x[key])).reduce((prev,next) => prev + next);
      return data
    }else{return 0}
  }

  convertToNum(val:any):number{
    if(isNaN(val)){ val = Number(val.replace(/\%|\$|\,/g,"")) } else if (val===''){val=0}
    return val
  }
  transform(data:any, ...args:any[]): unknown {
    if(args[0]==true){
      if(args[1]==="esp1"){
        const pet = args[2];
        if(pet.condition==="<="){
          this.DATA = data.filter((x)=> this.convertToNum(x[pet.filter]) <= this.convertToNum(pet.value))
        } else if(pet.condition==="=="){
          this.DATA = data.filter((x)=> this.convertToNum(x[pet.filter]) == this.convertToNum(pet.value))
        } else if(pet.condition==="!="){
          this.DATA = data.filter((x)=> this.convertToNum(x[pet.filter]) != this.convertToNum(pet.value))
        }
          else {
          this.DATA = data.filter((x)=> this.convertToNum(x[pet.filter]) >= this.convertToNum(pet.value))
        }
        if(pet.char!=''){
          this.DATA = this.DATA.filter((x)=>x['ABC']===pet.char);
        }
      } else if(args[1]==="ABC"){
        this.DATA = data.filter((x)=> x["ABC"] === args[2])

      } else{
        let filter = args[1].toString();
        let value = args[2].toString().toUpperCase();
        //console.log('Filtro: ',filter,'Valor: ',value)
        let post = [];
        for (const i of data){
          if(i[filter].toString().indexOf(value) > -1){
            post.push(i);
          }
        }
        this.DATA = post;
      }
    } else {
      this.DATA = data;
    }
    if(args[3]){
      
      if(args[3]==="total"){
        return this.DATA.length
      } else if (args[3]==="percent"){
      return this.sumObj('percent').toFixed(2);
      } else {
      return this.sumObj(args[3]).toFixed(2);
      }
    } else {
    return this.DATA;
    }

  }

}
