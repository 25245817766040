import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import {UserModel} from '../../models/user.model'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user:UserModel;
  isLogged:boolean = this.auth.readToken();
  failToLogin=false;
  error_message:string='';

  constructor(private r:Router, private auth:AuthService) {
    this.user = new UserModel();
   }

  ngOnInit(): void {
  }
  
  login(loginForm:NgForm){
    if(loginForm.invalid){
      return;
    } 
      this.auth.login(this.user.email,this.user.password).then((data)=>{
        var tokens = data.signInUserSession;
        if(tokens!=null){
          console.log('Autenticado');
        }
        this.isLogged == true;
        this.auth.saveToken(tokens['idToken'].jwtToken,data.pool['userPoolId'])
        this.r.navigateByUrl('/home').then(() => {
          window.location.reload()
        })

      },(err)=>{
        console.log(err)
        this.error_message=err['message']
        this.failToLogin=true;

      });
  }
}
