import { Component, OnInit } from '@angular/core';
import {AuthService} from './services/auth.service'
import {Auth, selectInput} from 'aws-amplify';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  isLogged:boolean;
  constructor(private auth:AuthService){}

  ngOnInit(): void {
    var elem = document.getElementById('wrapper');
    elem.classList.toggle('toggled');
  }
  
  title = 'web-demo';
}
