import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterN'
})

export class FilterPipeN implements PipeTransform {
  transform(items: any[], searchText3: string, column: string): any[] {
    if (!items) return [];
    if (!searchText3) return items;
    searchText3 = searchText3.toLowerCase();
    return items.filter(item => {
      const columnValue = item[column] ? item[column].toString().toLowerCase() : '';
      return columnValue.includes(searchText3);
    });
  }
}
