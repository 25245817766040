import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {API} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TextractService {
  constructor(private http:HttpClient) { }
  enviaBase64(body){
    return this.http.post(API+'/files',body,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  }); //Esta línea es para cambiarla Linea de API Gateway
  }
}
