import * as FileSaver from 'file-saver';
export class createMapeCSV{
    HEADERS:boolean[];
    createCSV(data){
        var json = data
        var fields = Object.keys(json[0])
        var replacer = function(key, value) { return value === null ? '' : value } 
        var csv = json.map(function(row){
        return fields.map(function(fieldName){
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
      })
      csv.unshift(fields.join(',')) // add header column
      csv = csv.join('\r\n');
      return csv;
      }
    filterCSV(json):any{
        json = json.map((i)=>{return {Codigo:i.Codigo,Descripcion:i.Descripcion,Grupo:i.Grupo,Nivel_min:i.Nivel_min,Forecast_4:i.Forecast_4,Facturado_4:i.Facturado_4,MAPE_4:i.MAPE_4,Forecast_3:i.Forecast_3,Facturado_3:i.Facturado_3,MAPE_3:i.MAPE_3,Forecast_2:i.Forecast_2,Facturado_2:i.Facturado_2,MAPE_2:i.MAPE_2,Forecast_1:i.Forecast_1,Facturado_1:i.Facturado_1,MAPE_1:i.MAPE_1,
        mape:i.mape,minimo_facturado:i.minimo_facturado,cogs:i.cogs,porcentaje:i.porcentaje,ABC:i.ABC}});
        for(const i in json){
            if(this.HEADERS[0]==false){delete json[i]['Codigo'];}
            if(this.HEADERS[1]==false){delete json[i]['Descripcion'];}
            if(this.HEADERS[2]==false){delete json[i]['Grupo'];}
            if(this.HEADERS[3]==false){delete json[i]['Nivel_min'];}
            if(this.HEADERS[4]==false){delete json[i]['Forecast_4']; delete json[i]['Facturado_4']; delete json[i]['MAPE_4'];}
            if(this.HEADERS[5]==false){delete json[i]['Forecast_3']; delete json[i]['Facturado_3']; delete json[i]['MAPE_3'];}
            if(this.HEADERS[6]==false){delete json[i]['Forecast_2']; delete json[i]['Facturado_2']; delete json[i]['MAPE_2'];}
            if(this.HEADERS[7]==false){delete json[i]['Forecast_1']; delete json[i]['Facturado_1']; delete json[i]['MAPE_1'];}
            if(this.HEADERS[8]==false){delete json[i]['mape'];}
            if(this.HEADERS[9]==false){delete json[i]['minimo_facturado'];}
            if(this.HEADERS[10]==false){delete json[i]['cogs'];}
            if(this.HEADERS[11]==false){delete json[i]['porcentaje'];}
            if(this.HEADERS[12]==false){delete json[i]['ABC'];}
        }
    return json
    }
    exportCSV():void{
        let json = JSON.parse((<HTMLInputElement>document.getElementById("hidden_textarea")).value);
        json = this.filterCSV(json);
        const data: Blob = new Blob([this.createCSV(json)], { type: 'text/plain;charset=utf-8' });
        const fileName:string = 'Real_vs_Forecast Bollhoff '+new Date().getFullYear()+'-'+new Date().getDay()+'-'+new Date().getMonth()+':'+new Date().getHours()+'.'+new Date().getMinutes()+'.'+new Date().getSeconds()+'.csv'
        FileSaver.saveAs(data, fileName);

    }
    constructor(headers:boolean[]){
        this.HEADERS = headers;

    }
}