import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { createFactorCSV } from 'src/app/models/createFactorCSV';
import { SemaphoreService } from 'src/app/services/semaphore.service';
import { FactorService } from 'src/app/services/import-factor.service';
@Component({
  selector: 'app-import-factor',
  templateUrl: './factor.component.html',
  styleUrls: ['./factor.component.css']
})
export class FactorComponent implements OnInit {
  HEADERS:boolean[] = [true,true,true,true,true,true,true,true,true,true,true,true,true];
  FILTER1:string = '';FILTER2:string = ''; FILTER3:string = ''; FILTER4:string = ''; FILTER5:string = '';

  DATA = {};
  showFilter:boolean = false;
  URL_FILTER = '';
  JSONisLoaded:boolean = false;
  constructor(private s:FactorService,private r:Router) { }
  test(val:string):void{ this.FILTER1 = val;}
  restartRange():void{this.URL_FILTER = ''; this.updateTable();}
  filterByRange(filter:string,datei:string,datef:string,proveedorB:string,warehose:string):void{
    this.URL_FILTER = '?filtro=';
    switch(Number(filter)){
      case 1 : this.URL_FILTER+=`Dates&datei=${datei}&datef=${datef}`; break;
      case 2 : this.URL_FILTER+=`Supplier&supplier=${proveedorB}`; break;
      case 3 : this.URL_FILTER+=`SupplierAndDates&datei=${datei}&datef=${datef}&supplier=${proveedorB}`; break;
      case 4 : this.URL_FILTER+=`Warehouse&whscode=${warehose}`; break;
      case 5 : this.URL_FILTER+=`WarehouseAndDates&datei=${datei}&datef=${datef}&whscode=${warehose}`; break;
    }
    this.updateTable();
  }


  reload(){this.r.navigateByUrl('/factor').then(()=> window.location.reload());}
  updateTable(){
    this.JSONisLoaded = false;


    if(this.URL_FILTER==''){
      function pad2(n) {
        return (n < 10 ? '0' : '') + n;
      }
      
      var date = new Date();
      var month = pad2(date.getMonth()+1);
      var monthantes = pad2(date.getMonth());
      var day = pad2(date.getDate());
      var year= date.getFullYear();
      var formattedDate =  year+"-"+month+"-"+day;
      var formattedDateantes =  year+"-"+monthantes+"-"+day;


      this.s.getImportFactor(`?filtro=Dates&datei=${formattedDateantes}&datef=${formattedDate}`).subscribe((res)=>{
        this.DATA = res;
        this.JSONisLoaded = true;

    })

    }
    else{
      
 
   
    this.s.getImportFactor(this.URL_FILTER).subscribe((res)=>{
      this.DATA = res;
      this.JSONisLoaded = true;

  })
    
    
  }
  }
  createCSV(){
    const csv = new createFactorCSV(this.HEADERS);
    csv.exportCSV();
  }
  ngOnInit(): void {
    this.updateTable(); 
  }

}
