import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extension'
})
export class ExtensionPipe implements PipeTransform {

  transform(file: string): string {
    const ext = file.split('.').pop();
    switch(ext.toLowerCase()){
      case 'doc':
      case 'docx':    
          return 'assets/img/exts/doc.png'
      break;    
      case 'xls':
      case 'xlsx':
          return 'assets/img/exts/xls.png'
      break;
      case 'pdf':
          return 'assets/img/exts/pdf.png'
      break;
      case 'jpg':
          return 'assets/img/exts/jpg.png'
      break;
      case 'png':
          return 'assets/img/exts/png.png'
      break;
      case 'ppt':
      case 'pptx':
          return 'assets/img/exts/ppt.png'
      break;
      case 'html':
      case 'htm':
          return 'assets/img/exts/html.png'
      default:
          return 'assets/img/exts/expediente.png'
      break;                            
    

}
  }

}
