import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  SELECTED_VID : string = "";
  SELECTED_DOC : string = "https://s3.amazonaws.com/hi.bollhoff.helpvideos/Codigo_llaves.pdf";
  constructor() { }
  documentShow:any = {
    title : '',
    notes: '',
    fileLink: ''
  };
  videoList:any = {
    reportePedidos : 'https://s3.amazonaws.com/hi.bollhoff.helpvideos/Pedidos.mp4',
    cargarPedidos: 'https://s3.amazonaws.com/hi.bollhoff.helpvideos/Cargar+pedidos.mp4',
    semaphore : 'https://s3.amazonaws.com/hi.bollhoff.helpvideos/Semaforo.mp4',
    mape : 'https://s3.amazonaws.com/hi.bollhoff.helpvideos/Mape.mp4',
    config : 'https://s3.amazonaws.com/hi.bollhoff.helpvideos/Config+Interprete+de+Pedidos.mp4',
    forecastBRP : 'https://s3.amazonaws.com/hi.bollhoff.helpvideos/Carga+Forecast.mp4',
    BRPtoSAP : 'https://s3.amazonaws.com/hi.bollhoff.helpvideos/Env%C3%ADo+informaci%C3%B3n+al+portal+de+pedidos.mp4'
  }
  ngOnInit(): void {
  }

}
