<h1 class="text-center">Registrarse</h1>
<div class="row">
    <div class="col"></div>
    <div class="col">
        <div *ngIf="signUpError"class="alert alert-danger" role="alert">
            <h3>Error</h3>
            {{error_message}}
        </div>
        <form #registroForm="ngForm" (ngSubmit)="register(registroForm)">
        <h3>Nombre</h3>
        <input type="text" name="firstname" required firstname [(ngModel)]="user.name" class="form-control">
        <span *ngIf="registroForm.submitted && registroForm.controls['firstname'].errors"
                          class="text-danger animated fadeIn">Ingresa tu nombre</span>
        <h3>Apellidos</h3>
        <input type="text" name="lastname" required lastname [(ngModel)]="user.lastname" class="form-control">
        <span *ngIf="registroForm.submitted && registroForm.controls['lastname'].errors"
                          class="text-danger animated fadeIn">Ingresa tus apellidos</span>
        <h3>Email</h3>
        <input type="email" name="email" required email [(ngModel)]="user.email" class="form-control">
        <span *ngIf="registroForm.submitted && registroForm.controls['email'].errors"
                          class="text-danger animated fadeIn">Ingresa tu email</span>
        <h3>Contraseña</h3>
        <input type="password" name="password" required password [(ngModel)]="user.password" class="form-control">
        <span *ngIf="registroForm.submitted && registroForm.controls['password'].errors"
                          class="text-danger animated fadeIn">Ingresa tu contraseña</span>
        <h3>Número telefónico</h3>
        <input type="text" name="phone" required phone [(ngModel)]="user.phone" class="form-control">
        <span *ngIf="registroForm.submitted && registroForm.controls['phone'].errors"
                          class="text-danger animated fadeIn">Ingresa tu número telefónico</span>
        <h3>Dirección</h3>
        <input type="text" name="address" required address [(ngModel)]="user.address" class="form-control">
        <span *ngIf="registroForm.submitted && registroForm.controls['address'].errors"
                          class="text-danger animated fadeIn">Ingresa tu dirección</span>
        <br>
        <button type="submit" class="btn btn-success btn-lg btn-block">Registrarse</button>
    </form>
    </div>
    <div class="col"></div>
</div>