import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ReporteService } from 'src/app/services/reporte.service';
import { TextractService } from 'src/app/services/textract.service';
import Swal from 'sweetalert2'
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/build/pdf.worker';

const loading = Swal.mixin({
  showConfirmButton : false,
  allowOutsideClick : false
})

@Component({
  selector: 'app-load-shipment',
  templateUrl: './load-shipment.component.html',
  styleUrls: ['./styles.css']
})
export class LoadShipmentComponent implements OnInit {

  @ViewChild('fileDropRef') fileDrop : ElementRef;
  PUT_BODY = {
    info: []
  };
  interval:any;
  SNACKBAR_MSG = '';
  BTN_SELECTED:number = 0;
  FILE_LIST = [];
  finish_date = '';
  ALTERT_TYPE:string="";
  DONE:boolean=false;
  ERR_MSG:boolean=false;
  SELECTED_ID:string = '';
  CONVERTED_FILES = [];
  REPORTS_TABLE = [];
  DETAILS_REPORT = [];
  DETAILS_ID:number;
  DETAILS_LAST_ID:number;
  FILES = [];
  FILES_PROPERTIES = [];
  error_msg='';
  SHOW_TABLE:boolean = false;
  deleteOrder(name:string):void{
    const a = window.confirm(`¿Deseas borrar el contenido de "${name}"?`);
    if (a == true){
      this.rep.eraseFileReport(name,{}).subscribe((res:any)=>{
        this.loadReports();
      });
    }
  }
  deleteSubTable():void{
    if(document.getElementById("actual_details_header")){
      document.getElementById("actual_details_header").remove();
    }
    for(let i=0;i<1000;i++){
      if(document.getElementById("actual_details_row_"+i)){
        document.getElementById("actual_details_row_"+i).remove();
      }
    }
  }
  onSnackbar(msg:string):void{
    let x = document.getElementById("snackbar");
    this.SNACKBAR_MSG = msg;
    x.className = "show";
    setTimeout(()=>{x.className = x.className.replace("show","");},3000)
  }
  updateSubtable():void{
    for(let i=0;i<this.DETAILS_REPORT.length;i++){
      this.DETAILS_REPORT[i].ItemNo = (<HTMLInputElement>document.getElementById("item_no_row_"+i)).value;
      this.DETAILS_REPORT[i].description = (<HTMLInputElement>document.getElementById("description_row_"+i)).value;
      this.DETAILS_REPORT[i].quantity = Number((<HTMLInputElement>document.getElementById("quantity_"+i)).value);
      this.DETAILS_REPORT[i].ItemCustomer = (<HTMLInputElement>document.getElementById("item_cust_"+i)).value;
      this.DETAILS_REPORT[i].deliveryDate = (<HTMLInputElement>document.getElementById("deliveryDate_"+i)).value;
      this.DETAILS_REPORT[i].deliveryWeek = Number((<HTMLInputElement>document.getElementById("deliveryWeek_"+i)).value);
      this.DETAILS_REPORT[i].unityPrice = Number((<HTMLInputElement>document.getElementById("unityPrice_row_"+i)).value);
    }
    this.onSnackbar("Detalles modificados correctamente, no olvides dar clic en el botón de guardado verde de arriba.")
  }
  printOnNest(id:number){
    this.deleteSubTable();
    let table = `<tr style="background-color: #00aae4; color: WHITE;" id="actual_details_header"><th># Item (#${id})</th><th>Descripción</th><th>Cantidad</th><th>Fecha de entrega</th><th>Producto</th><th>Semana</th><th>Precio unitario</th><th>Opciones</th></tr>`; let index = 0;
    for(const i of this.DETAILS_REPORT){
      table+= `<tr`;
      if((index+1)%2==0){table+=` class='table-info'`;}
      table+=` id="actual_details_row_${index}">`;
      table+= `<td style="width: 9%;"><input id="item_no_row_${index}" type="text" class="form-control" value="${i.ItemNo}"></td>`;
      table+= `<td><input id="description_row_${index}" type="text" class="form-control" value="${i.description}"></td>`;
      table+= `<td style="width: 1%!important;"><input id="quantity_${index}" type="number" class="form-control" value="${i.quantity}"></td>`;
      table+= `<td><input id="deliveryDate_${index}" type="date" class="form-control" value="${i.deliveryDate}"></td>`;
      table+= `<td><input id="item_cust_${index}" type="text" class="form-control" value="${i.ItemCustomer}"></td>`;
      table+= `<td><input id="deliveryWeek_${index}" type="number" class="form-control" value="${i.deliveryWeek}"></td>`;
      table+= `<td style="width: 2%;"><input id="unityPrice_row_${index}" type="number" class="form-control" value="${i.unityPrice}"></td>`;
      if(index==0){
        table+=`<td><button type="button" class="btn btn-secondary" id="push_stb"><img src="assets/img/table/save.png" width="24px" heigth="24px"></button> <button class="btn btn-secondary" type="button" id="close_stb">Cerrar</button></td>`
      }else{table+=`<td></td>`}
      table+= `</tr>`;
      index++;
      
    }
    document.getElementById('nested_'+id).insertAdjacentHTML('afterend',table);
    document.getElementById("push_stb").addEventListener("click",()=>{
      this.updateSubtable();
    })
    document.getElementById("close_stb").addEventListener("click",()=>{
      this.deleteSubTable();
    })
  }
  deleteRecord(uuid):void{
    console.log(uuid);
    const p = window.confirm(`¿Deseas borrar el registro: ${uuid}?`);
    console.log(this.FILE_LIST[this.BTN_SELECTED])
    if (p){
      this.rep.eraseReport(uuid,{}).subscribe((res)=>{
        console.log(res);
        this.onSnackbar("Se ha borrado el registro: "+uuid);
        this.loadReports();
      });
    }
  }
  saveRecord(id:number,uuid:string):void{
    this.PUT_BODY = { info: []  };
    this.PUT_BODY.info.push(this.REPORTS_TABLE[id]);
    this.rep.updateReport(uuid,JSON.stringify(this.PUT_BODY).toString()).subscribe((res)=>{
      if(res==="Item updated!"){
        setTimeout(()=>{this.onSnackbar("Pedido actualizado correctamente.");},1000);
      } else {
        this.onSnackbar("Error al actualizar.");
      }
    })
  }
  setDetails(id:number){
    this.DETAILS_LAST_ID = this.DETAILS_ID;
    this.DETAILS_ID = id;
    //console.log("Opening id: ",this.DETAILS_ID);
    this.DETAILS_REPORT = this.REPORTS_TABLE[this.DETAILS_ID].details;
    //console.log("Contenido: ",this.DETAILS_REPORT)
  }
  updateDetails():void{
    this.REPORTS_TABLE[this.DETAILS_ID].details = this.DETAILS_REPORT;
    this.DETAILS_REPORT = []; this.DETAILS_ID = 0;
  }
  pushReport():void{
    console.log(this.REPORTS_TABLE);
  }
  loadReports(f='0'):void{
    this.DONE = false;
    this.ERR_MSG = false;
    this.deleteSubTable();
    this.rep.getReportsFiles().subscribe((res:any)=>{
      this.FILE_LIST = res["info"];
      if (this.FILE_LIST.length == 0){
        clearInterval(this.interval);
        this.interval = setInterval(this.loadReports,33000);
      } else {clearInterval(this.interval);}
    })
    this.rep.getReports(f).subscribe((res:any)=>{
      this.REPORTS_TABLE = res["info"];
      this.SHOW_TABLE = true;
      this.onSnackbar("Tabla generada.");
    })
    //this.interval = setInterval(()=>{this.loadReports(f);},240000);
  }
  loadFiles(event){
      if(event.length > 0){
        this.FILES.push(event)
        this.updateFiles();
      }
  }
  ngOnDestroy():void{
    //clearInterval(this.interval);
  }
  updateFiles(){
    this.FILES_PROPERTIES = [];
    for(let i=0;i<this.FILES.length;i++){
      this.FILES_PROPERTIES.push(this.FILES[i][0].name);
    }
  }
  deleteElement(index:number){
    if(this.FILES.length>1){
      const len = this.FILES.length;
      let aux = this.FILES.filter((v,i,arr)=>i !=index);
      this.FILES = aux;
      this.updateFiles();
    } else {
      this.FILES.pop();
      this.updateFiles();
    }
  }
  constructor(private api:TextractService,private rep:ReporteService,public readonly swalTargets: SwalPortalTargets) { }
  ngOnInit(): void {
  }
  getBase64(file){
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      let ext = file.name.split('.').pop().toLowerCase();
      let namefile = file.name.split(".");

      switch(ext){
        case 'html':
        case 'htm':
          reader.readAsText(file);
        break;
        case 'pdf':
          reader.readAsArrayBuffer(file);
        break;
        default:
          reader.readAsDataURL(file);
        break;
      }
      reader.onload = async() => {
          let response:any = '';
       if (ext === 'pdf') {
          const arrayBuffer = reader.result as ArrayBuffer;
          const typedArray = new Uint8Array(arrayBuffer);
          const pdfDoc = await pdfjsLib.getDocument(typedArray).promise;
          const numPages = pdfDoc.numPages;
          const imageUrls = [];

          for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
             const page = await pdfDoc.getPage(pageNumber);
             const viewport = page.getViewport({ scale: 3.0 });
             const canvas = document.createElement('canvas');
             const context = canvas.getContext('2d');
             canvas.height = viewport.height;
             canvas.width = viewport.width;
             const renderContext = {
               canvasContext: context,
               viewport: viewport,
             };
             await page.render(renderContext).promise;
             const imageDataUrl = canvas.toDataURL(`image/png`);
             

             console.log(imageDataUrl?.length);

             const d ={
              name: `${namefile[0] + "_" + pageNumber+".png"}`,
              base64: imageDataUrl,
              tipo: "image"
             }  
             imageUrls.push(d);
          }
          response = imageUrls;
        } else
        if (ext=='htm' || ext=='html'){
          let aux:any = reader.result.toString();
          const s = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/ig;
          const r = /<input type="hidden" name="__VIEWSTATE" id="__VIEWSTATE" value=".+\/>/ig;
          const n = /<input type="hidden" name="__VIEWSTATE" id="__VIEWSTATE" value=".+\>/ig;
          console.log(n)
          aux = aux.replace(r,'');
          aux = aux.replace(s,'');
          aux = aux.replace(n,'');
          response = 'data:text/html;base64,'+window.btoa(unescape(encodeURIComponent(aux/*reader.result.toString().replace(r,'')*/)));
        }
        else {
          response = reader.result
        }
        resolve(response);
      };
      reader.onerror = error => reject(error);
    });
  }
  b64:any = {
    files : []
  };
  permitedExts(ext:string):boolean{
    switch(ext){
      default : return false
      case 'pdf' : return true
      case 'xls' : return true
      case 'xlsx': return true
      case 'html' : return true
      case 'htm' : return true
    }
  }

  forInFiles(){
    return new Promise((resolve,reject)=>{
      let err:boolean = false;
      for(let i=0;i<this.FILES.length;i++){
        if(!this.permitedExts(this.FILES[i].item(0).name.split(".").pop().toLowerCase())){
          err=true;
          reject("x001");
        } else if(!err){
        this.getBase64(this.FILES[i].item(0))
        .then((res)=>{
            if(res[0]?.tipo==="image"){
              if (Array.isArray(res)) {
                res?.forEach((item) => {
                  this.b64.files.push({ name: item.name, b64: item.base64 });
                });
              }    
            }else{
              this.b64.files.push({name : this.FILES[i].item(0).name, b64 : res});
            }
        })
      }
        if(i==this.FILES.length-1){
          setTimeout(()=>{resolve("Datos convertidos");loading.close();},3000)    
        }
      }
    });
  }

  prepareFiles():void{
    this.ERR_MSG=false;
    this.DONE=false;
    if(this.FILES.length>0){
      loading.fire({title:'Cargando archivos',html:'<p>Subiendo archivos adjuntos...</p> <i class="fa fa-sync fa-spin fa-3x"></i>'})
      this.b64 = {
        files : []
      }
      this.forInFiles().then(()=>{
        this.api.enviaBase64(this.b64).subscribe((res)=>{
          if(res["statusCode"] === 400){
            this.ERR_MSG=true;
            this.error_msg="Uno o varios archivos NO SON realmente PDF(s)"
          } else {
            this.DONE=true;
            this.finish_date = new Date().toString().substr(16,8);
          }
        })
      }).catch((err)=>{
        loading.close();
        this.ALTERT_TYPE="danger";
        if(err==="x001"){
          this.ERR_MSG=true;
          this.error_msg="Uno o varios archivos no está(n) en formato PDF ";
        }
      }).finally(()=>
      {
        this.fileDrop.nativeElement.value = '';
        this.FILES = [];
        this.FILES_PROPERTIES = [];
      })
    }
  }
}
