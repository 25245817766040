import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { SemaphoreService } from 'src/app/services/semaphore.service';
import { Router } from '@angular/router';
const loading = Swal.mixin({
  showConfirmButton : false,
  allowOutsideClick : false
})

@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.css']
})
export class ForecastComponent implements OnInit {
  MSG_TEXT:string[] = ['','',''];
  DONE:boolean = false;
  SEND_HEADERS = [];
  SCOPE:string = "week";
  HEADERS_T = [];
  DATA = [];
  showFilter:boolean = false;
  URL_FILTER = '';
  JSONisLoaded:boolean = false;
  constructor(private s : SemaphoreService,private r:Router) { }
  test(val:string):void{ console.log(val);}
  restartRange():void{this.URL_FILTER = ''; this.updateTable();}
  updateSend(isChecked:boolean,value:string):void{
    if (isChecked) {
      if (this.SEND_HEADERS.indexOf(value) == -1) {
        this.SEND_HEADERS.push(value);
      }
    } else {
      if (this.SEND_HEADERS.indexOf(value) != -1) {
        this.SEND_HEADERS = this.SEND_HEADERS.filter((x)=>x!=value);
      }
    }
  }
  onSend():void{
    loading.fire({title:'Generando pedido',html:'<p>Procesando el pedido...</p> <i class="fa fa-sync fa-spin fa-3x"></i>'});
    let body = {
      orders : []
    }
    for(const i of this.SEND_HEADERS){
      let tmp = { [this.SCOPE] : i.split("-")[0], details : [] };
      for(let j=0;j<this.DATA.length;j++){
        tmp["details"].push({code : this.DATA[j]["code"], quantity : this.DATA[j][i] });
      }
      body["orders"].push(tmp);
    }
    this.s.postForecast(body).subscribe((res:any)=>{
      let e = document.getElementById("alert_zone");
      e.style["display"] = "block";
      if (res["statusCode"] === 200){
        this.DONE = true;
        e.classList.add("alert-success");
        this.MSG_TEXT[0] = 'Éxito';
        this.MSG_TEXT[1] = 'Se ha generado correctamente el pedido';
        setTimeout(()=>{this.r.navigateByUrl('/cargarPedidos')},1500)
      } else {
        this.DONE = true;
        e.classList.add("alert-danger");
        this.MSG_TEXT[0] = 'Error:';
        this.MSG_TEXT[1] = 'Ha habido un error al generar el pedido';
      }
      loading.close();
    },(error:any)=>{
      setTimeout(()=>console.clear(),1000);
      let e = document.getElementById("alert_zone");
      e.style["display"] = "block";
      this.DONE=true;
      e.classList.add("alert-success");
      this.MSG_TEXT[0] = 'Éxito';
      this.MSG_TEXT[1] = 'Espere unos minutos en lo que es generado su pedido';
      this.MSG_TEXT[2] = 'Por favor revise el módulo "Cargar pedidos" en 10 minutos, gracias.'
      loading.close();
    });
  }
  //reload(){this.r.navigateByUrl('/semaphore').then(()=> window.location.reload());}
  updateTable(){
    this.SEND_HEADERS = [];
    this.s.getForecast(this.SCOPE).subscribe((res)=>{
        this.HEADERS_T = [];
        this.DATA = res["info"];
        for(const i in res["info"][0]){
              this.HEADERS_T.push(i)
        }
        this.JSONisLoaded = true;
    })
  }
  ngOnInit(): void {
    this.updateTable();
  }

}
