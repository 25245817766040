import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth:AuthService, private r:Router){

  }
  canActivate():  boolean {
    if(this.auth.readToken()){
      return true;
    } else {
      this.r.navigateByUrl('/login');
    }
  }
  
}
