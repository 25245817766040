<div class="container-fluid">
<!-- Zona de filtros -->
<div id="filter" *ngIf="showFilter">
    <h3><img src="assets/img/embudo.png" width="32px" height="32px">Filtros</h3>
    <div class="row">
        <div class="col">
            <div class="form-check">
                <input [(ngModel)]="HEADERS[0]" class="form-check-input" type="checkbox" value="" id="code">
                <label class="form-check-label" for="code">
                Código
                </label>
                <input #cardcode (keyup)="FILTER1=cardcode.value;" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por código" aria-label="Buscar por código">
                
                <input [(ngModel)]="HEADERS[1]" class="form-check-input" type="checkbox" value="" id="description">
                <label class="form-check-label" for="description">
                Descripción
                </label>
                <input #description (keyup)="FILTER2=description.value;" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por descripción" aria-label="Buscar por descripción">
            
                <input [(ngModel)]="HEADERS[2]" class="form-check-input" type="checkbox" value="" id="group">
                <label class="form-check-label" for="group">
               Grupo
                </label>
                <input #group (keyup)="FILTER3=group.value;" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por grupo" aria-label="Buscar por grupo">
               
                <input [(ngModel)]="HEADERS[3]" class="form-check-input" type="checkbox" value="" id="niv_min">
                <label class="form-check-label" for="niv_min">
                Nivel mínimo
                </label>
            </div>
        </div>
        <div class="col">
            <div class="form-check">
            <input [(ngModel)]="HEADERS[4]" class="form-check-input" type="checkbox" value="" id="mape4">
            <label class="form-check-label" for="mape4">
            MAPE_4
            </label>
            <br>
            <input [(ngModel)]="HEADERS[5]" class="form-check-input" type="checkbox" value="" id="mape3">
            <label class="form-check-label" for="mape3">
            MAPE_3
            </label>
            <br>
            <input [(ngModel)]="HEADERS[6]" class="form-check-input" type="checkbox" value="" id="mape2">
            <label class="form-check-label" for="mape2">
            MAPE_2
            </label>
            <br>
            <input [(ngModel)]="HEADERS[7]" class="form-check-input" type="checkbox" value="" id="mape1">
            <label class="form-check-label" for="mape1">
            MAPE_1
            </label>
            <br>
            <input [(ngModel)]="HEADERS[8]" class="form-check-input" type="checkbox" value="" id="mape">
            <label class="form-check-label" for="mape">
            MAPE
            </label>
            </div>
            <br>
            <p><button class="btn btn-primary" (click)="reload()">Recargar</button></p>
            <p><button class="btn btn-success" (click)="createCSV()">Exportar a CSV</button></p>
            <p><button class="btn btn-warning" (click)="FILTER1='';FILTER2='';FILTER3='';FILTER4=''; this.restartRange();">Limpiar filtros</button></p>

        </div>
        <div class="col">
            <input [(ngModel)]="HEADERS[9]" class="form-check-input" type="checkbox" value="" id="min_invoice">
            <label class="form-check-label" for="min_invoice">
            Minimo facturado
            </label>
            <br>
            <input [(ngModel)]="HEADERS[10]" class="form-check-input" type="checkbox" value="" id="cogs">
            <label class="form-check-label" for="cogs">
            COGS
            </label>
            <br>
            <input [(ngModel)]="HEADERS[11]" class="form-check-input" type="checkbox" value="" id="%">
            <label class="form-check-label" for="%">
            Porcentaje
            </label>
            <br>
            <input [(ngModel)]="HEADERS[12]" class="form-check-input" type="checkbox" value="" id="abc">
            <label class="form-check-label" for="abc">
            ABC
            </label>
            <select #abc (change)="FILTER4=abc.value;" class="form-control">
                <option value="" selected disabled hidden>...</option>
                <option value="AA">AA</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
            </select>
            <!--Filtros de porcentaje-->
            <label class="form-check-label" for="range">Rango</label>
            <div class="row">
                <div class="col">
                    <select #filter class="form-control">
                        <option value="" selected disabled hidden>...</option>
                        <option value="1">MAPE</option>
                        <option value="2">Mínimo/Facturado</option>
                        <option value="3">COGS</option>
                        <option value="4">Porcentaje</option>
                    </select>
                </div>
                <div class="col">
                    <select #condition class="form-control">
                        <option value="" selected disabled hidden>...</option>
                        <option value="<="><=</option>
                        <option value="==">=</option>
                        <option value=">=">>=</option>
                    </select>
                </div>
                <div class="col">
                    <input #percent class="form-control" type="number" value="0" id="percent">
                </div>
                <div class="col"><button #convert_button type="button" (click)="filterByRange(filter.value,condition.value,percent.value);" class="btn btn-primary">Consultar</button></div>
            </div>
            <br>
        </div>
    </div>
</div>
<div class="custom-control custom-switch">
<input [(ngModel)]="showFilter" type="checkbox" class="custom-control-input" id="customSwitch1" checked>
<label class="custom-control-label" for="customSwitch1">{{showFilter ? 'Desactivar filtros' : 'Activar filtros'}}</label>
</div>

<!--Control de tabla-->
<div *ngIf="!JSONisLoaded else loaded" class="row text-center animated fadeIn">
    <div class="col">
        <i class="fa fa-sync fa-spin fa-5x"></i>
    </div>
  </div>
<ng-template #loaded>
    <br>
<div class="table-responsive">
<table class="table table-hover">
    <thead class="thead-per">
        <th *ngIf="HEADERS[0]">Código</th>
        <th *ngIf="HEADERS[1]">Descripción</th>
        <th *ngIf="HEADERS[2]">Grupo</th>
        <th *ngIf="HEADERS[3]">Nivel mínimo</th>
        <th *ngIf="HEADERS[4]">Forecast_4</th>
        <th *ngIf="HEADERS[4]">Facturado_4</th>
        <th *ngIf="HEADERS[4]">MAPE_4</th>
        <th *ngIf="HEADERS[5]">Forecast_3</th>
        <th *ngIf="HEADERS[5]">Facturado_3</th>
        <th *ngIf="HEADERS[5]">MAPE_3</th>
        <th *ngIf="HEADERS[6]">Forecast_2</th>
        <th *ngIf="HEADERS[6]">Facturado_2</th>
        <th *ngIf="HEADERS[6]">MAPE_2</th>
        <th *ngIf="HEADERS[7]">Forecast_1</th>
        <th *ngIf="HEADERS[7]">Facturado_1</th>
        <th *ngIf="HEADERS[7]">MAPE_1</th>
        <th *ngIf="HEADERS[8]">MAPE</th>
        <th *ngIf="HEADERS[9]">Mínimo/Facturado</th>
        <th *ngIf="HEADERS[10]">COGS</th>
        <th *ngIf="HEADERS[11]">%</th>
        <th *ngIf="HEADERS[12]">ABC</th>
    </thead>
    <tbody>
        <tr *ngFor="let i of DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4 ">
            <td *ngIf="HEADERS[0]">{{i.Codigo}}</td>
            <td *ngIf="HEADERS[1]">{{i.Descripcion}}</td>
            <td *ngIf="HEADERS[2]">{{i.Grupo}}</td>
            <td *ngIf="HEADERS[3]">{{i.Nivel_min | currency : 'USD' : '' : '1.0-0' }}</td>
            <td *ngIf="HEADERS[4]">{{i.Forecast_4 | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[4]">{{i.Facturado_4 | currency : 'USD' : '' : '1.0-0' }}</td>
            <td *ngIf="HEADERS[4]">{{i.MAPE_4}}</td>
            <td *ngIf="HEADERS[5]">{{i.Forecast_3 | currency : 'USD' : '' : '1.0-0' }}</td>
            <td *ngIf="HEADERS[5]">{{i.Facturado_3 | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[5]">{{i.MAPE_3}}</td>
            <td *ngIf="HEADERS[6]">{{i.Forecast_2 | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[6]">{{i.Facturado_2 | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[6]">{{i.MAPE_2}}</td>
            <td *ngIf="HEADERS[7]">{{i.Forecast_1 | currency : 'USD' : '' : '1.0-0' }}</td>
            <td *ngIf="HEADERS[7]">{{i.Facturado_1 | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[7]">{{i.MAPE_1 }}</td>
            <td *ngIf="HEADERS[8]"><b>{{i.mape }}</b></td>
            <td *ngIf="HEADERS[9]">{{i.minimo_facturado}}</td>
            <td *ngIf="HEADERS[10]">{{i.cogs | currency : 'USD' : '' : ''}}</td>
            <td *ngIf="HEADERS[11]">{{i.porcentaje}}</td>
            <td *ngIf="HEADERS[12]">{{i.ABC}}</td>
        </tr>
        <tr id="totals">
            
            <td *ngIf="HEADERS[0]">Totales: </td>
            <td *ngIf="HEADERS[1]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'total' | removeDecimals}}</td>
            <td *ngIf="HEADERS[2]"></td>
            <td *ngIf="HEADERS[3]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'Nivel_min' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[4]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'Forecast_4' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[4]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'Facturado_4' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[4]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'MAPE_4' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[5]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'Forecast_3' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[5]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'Facturado_3' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[5]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'MAPE_3' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[6]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'Forecast_2' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[6]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'Facturado_2' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[6]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'MAPE_2' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[7]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'Forecast_1' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[7]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'Facturado_1' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[7]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'MAPE_1' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[8]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'mape' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[9]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'minimo_facturado' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[10]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'cogs' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[11]">{{DATA | Filter : showFilter : 2 : FILTER1:FILTER2:FILTER3:FILTER4:'' : 'sum': 'porcentaje' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[12]"></td> 
        </tr>
    </tbody>
</table>
</div>

<textarea id="hidden_textarea">{{DATA | Filter : showFilter : 2 :FILTER1:FILTER2:FILTER3:FILTER4| json}}</textarea>
</ng-template>
</div>