import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: [
  ]
})
export class NavbarComponent implements OnInit {
  isLogged:boolean;

  constructor(private r:Router,private auth:AuthService) {
    this.isLogged = this.auth.readToken()
  }
  getURL():string{
    return this.r.url.split('/').pop();
  }
  sidebarControl(){
    var elem = document.getElementById('wrapper');
    elem.classList.toggle('toggled');
  }
  ngOnInit(): void {
    
  }
  test():void{
    console.log(this.auth.sendToken())
    this.auth.testAPI(JSON.stringify(this.auth.sendToken())).subscribe((x)=>{
      console.log(x.headers)
      console.log(x)
    })
  }
  cerrarSesion(){
    this.auth.logout();
  }
}

