import { Component, OnInit, Input } from '@angular/core';
import {ReporteService} from '../../../services/reporte.service'
import {createCSV} from '../../../models/createCSV'
import { Router } from '@angular/router';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styles: [
  ]
})
export class ResultComponent implements OnInit {
  button2status(){
    this.isSelectedAll = !this.isSelectedAll;
    this.hideShowAll(this.isSelectedAll);
  }
  csvButton(){
    const csv:createCSV = new createCSV(this.headers);
    csv.csvButton();
  }
  viewConsolidaded(){
    console.log(this.consolidaded)
  }
  consolidaded:any[] = [true,false,false,false,false,6,true];
  filterBycurr:boolean=false;
  filterByDate:boolean=false;
  startTime:Date;
  endTime:Date;
  JSONisLoaded:boolean = false;
  isSelectedAll:boolean=true;
  headers:any = this.rs.getHeaders();
  data:any;
  idForSearch:any='';
  idForAttr:string="";
  showFilter:boolean=false;
  filterByHeaders:boolean = true;
  buttonTitle:string='Mostrar cabeceras';
  selectedDiv:string='';
  setByBars(attr:string,query:string){
    this.idForSearch=query;
    this.idForAttr=attr;
  }
  afterConsolidate(){
    this.hideShowAll(false);
    if(this.consolidaded[1]==true){
      this.headers.cardCode=true;
      this.headers.cardName=true;
      this.headers.docTotal=true;
      this.headers.docTotalFC=true;

      this.headers.grossProfit=true; 
      this.headers.margin=true; 
      this.headers.grossProfFC=true;
    } 
    if(this.consolidaded[2]==true){
      this.headers.lineTotal=true;
      this.headers.itmsGrpCod=true;
      this.headers.itmsGrpNam=true;
      this.headers.totalFrgn=true;

      this.headers.grssProfit=true;
      this.headers.marginLine=true;
      this.headers.grssProfitFC=true;
    } 
    if(this.consolidaded[3]==true){
      this.headers.itemCode=true;
      this.headers.description=true;
      this.headers.lineTotal=true;
      this.headers.totalFrgn=true;

      this.headers.grssProfit=true;
      this.headers.marginLine=true;
      this.headers.grssProfitFC=true;
    }
    if(this.consolidaded[4]==true){
      this.headers.groupCode=true;
      this.headers.groupName=true;
      this.headers.docTotal=true;
      this.headers.docTotalFC=true;
      this.headers.grossProfit=true; 
      this.headers.margin=true; 
      this.headers.grossProfFC=true;
    }
    if(this.consolidaded[6]==true){
      this.hideShowAll(false)
      this.headers.cardCode=true;
      this.headers.cardName=true;
      this.headers.groupCode=true;
      this.headers.groupName=true;
      this.headers.docEntry=true;
      this.headers.document_date=true;
      this.headers.required_date=true;
      this.headers.docTotal= true;
      this.headers.docTotalFC= true;
      this.headers.docRate= true;
      this.headers.ordrDocCur=true;
      this.headers.margin=true;
      this.headers.grossProfit=true;
      this.headers.grossProfFC=true;
      this.headers.marginFC=true;
      this.headers.jrnlMemo=true;
    }
  }
  consolidar(op:number){
    const encendidos = this.consolidaded.reduce((total,current)=>{if(current==true){total=total+1;}return total},0);
    if(encendidos>2){
      this.consolidaded[0]=false;
    }
    for(var i=1;i<this.consolidaded.length;i++){
       this.consolidaded[i]=false;      
    }
    if(this.consolidaded[0]==false){
      switch(op){
        case 1:
          this.consolidaded[6]=false;
          this.consolidaded[1]=true;
        break;
        case 2:
          this.consolidaded[6]=false;
          this.consolidaded[2]=true;
        break;
        case 3:
          this.consolidaded[6]=false;
          this.consolidaded[3]=true;
        break;
        case 4:
          this.consolidaded[6]=false;
          this.consolidaded[4]=true;
        break;
        case 6:
          this.consolidaded[6]=true;
        break;          
      }
      this.consolidaded[0]=true;
      this.afterConsolidate();
      this.consolidaded[5]=op;
      this.isSelectedAll=false;
    } else {
      this.consolidaded[0]=false;
      this.consolidaded[5]=0;
      this.hideShowAll(false);
      this.isSelectedAll=true;
      this.headers.lineNum = true; 
      this.headers.lineTotal = true;
      this.headers.itemCode = true;
      this.headers.description = true;
      this.headers.quantity = true;
      this.headers.price = true;
      this.headers.currency = true;
      this.headers.itmsGrpCod = true;
      this.headers.itmsGrpNam = true;
      this.headers.ship_date=true;
      this.headers.grssProfit=true;
      this.headers.marginLine=true;
      this.headers.totalFrgn=true;
      this.headers.grssProfitFC=true;
      this.headers.marginLineFC=true;
    }


  }
  constructor(private rs:ReporteService,private r:Router) { 
    this.rs.getData().subscribe((data:any)=>{
      this.data=data;
      this.JSONisLoaded=true;
      this.headers.lineNum = false; 
      this.headers.lineTotal = false;
      this.headers.itemCode = false;
      this.headers.description = false;
      this.headers.quantity = false;
      this.headers.price = false;
      this.headers.currency = false;
      this.headers.itmsGrpCod = false;
      this.headers.itmsGrpNam = false;
      this.headers.ship_date=false;
      this.headers.grssProfit=false;
      this.headers.marginLine=false;
      this.headers.totalFrgn=false;
      this.headers.grssProfitFC=false;
      this.headers.marginLineFC=false;
    },(errServ)=>{
      console.error("No se cargo el servicio")
    })
  }
  reload(){this.r.navigateByUrl('/tables/result').then(()=> window.location.reload());}
  ngOnInit(): void {
  }
  viewArray(){
    console.log(this.headers)
  }
  hideShowAll(aux:boolean){
    for(var i in this.headers){
      this.headers[i] = aux;
    }
  }

}