<div class="container-fluid">
    <h3>Ayuda</h3>
    <p>Aquí encontrará los tutoriales de ayuda dejados para usted.</p>
    <div class="list-group">
        <h5 style="color:#0067de"> <img src="assets/img/mainmenu/resultados.png" height="64px" width="64px">Reporte de pedidos</h5>
        <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between" (click)="SELECTED_VID = videoList['reportePedidos'];openVideo.fire();">
              <h5 class="mb-1"><img src="assets/img/video.png" height="48px" width="48px"> Uso del reporte de pedidos</h5>
            </div>
        </div>
    <br>
        <h5 style="color:#0067de"> <img src="assets/img/mainmenu/Semaphore.png" height="64px" width="32px"> Semáforo</h5>
        <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between" (click)="SELECTED_VID = videoList['semaphore'];openVideo.fire();">
              <h5 class="mb-1"><img src="assets/img/video.png" height="48px" width="48px"> Uso del reporte Semáforo</h5>
            </div>
        </div>
    <br>
        <h5 style="color:#0067de"> <img src="assets/img/mainmenu/RForecast.png" height="64px" width="64px"> Real vs. Forecast</h5>
        <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between" (click)="SELECTED_VID = videoList['mape'];openVideo.fire();">
              <h5 class="mb-1"><img src="assets/img/video.png" height="48px" width="48px"> Uso del reporte Real vs Forecast</h5>
            </div>
        </div>
    <br>
        <h5 style="color:#0067de"> <img src="assets/img/sync.png" height="64px" width="64px"> Cargar pedidos</h5>
        <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between" (click)="SELECTED_VID = videoList['cargarPedidos'];openVideo.fire();">
            <h5 class="mb-1"><img src="assets/img/video.png" height="48px" width="48px"> Uso de Cargar pedidos</h5>
            </div>
        </div>
    <br>
        <h5 style="color:#0067de"> <img src="assets/img/mainmenu/Forecast.png" height="64px" width="64px">Forecast BRP</h5>
        <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between" (click)="SELECTED_VID = videoList['forecastBRP'];openVideo.fire();">
            <h5 class="mb-1"><img src="assets/img/video.png" height="48px" width="48px"> Cargar el Forecast y uso de Forecast BRP</h5>
            </div>
        </div>
    <br>
    <h5 style="color:#0067de"> <img src="assets/img/mainmenu/config.png" height="64px" width="64px">Configuración del Intérprete de Pedidos</h5>
    <div class="list-group-item list-group-item-action">
        <div class="d-flex w-100 justify-content-between" (click)="SELECTED_VID = videoList['config'];openVideo.fire();">
        <h5 class="mb-1"><img src="assets/img/video.png" height="48px" width="48px"> Uso de Configuración del Intérprete de Pedidos</h5>
        </div>
    </div>
    <div class="list-group-item list-group-item-action">
        <div class="d-flex w-100 justify-content-between" (click)="
        documentShow.title = 'Códigos llave de los clientes'; documentShow.notes = ''; documentShow.fileLink = 'https://s3.amazonaws.com/hi.bollhoff.helpvideos/Codigo_llaves.pdf';
        openDocument.fire();">
        <h5 class="mb-1"><img src="assets/img/pdf.png" height="48px" width="48px"> Códigos llave de los clientes</h5>
        </div>
    </div>
    <div class="list-group-item list-group-item-action">
        <div class="d-flex w-100 justify-content-between" (click)="SELECTED_VID = videoList['BRPtoSAP'];openVideo.fire();">
        <h5 class="mb-1"><img src="assets/img/video.png" height="48px" width="48px"> Envio de información al portal de pedidos Bollhoff</h5>
        </div>
    </div>
    </div>
</div>
<swal #openVideo showCloseButton="true" [showConfirmButton]="false" width="1200px" height="750px">
    <form *swalPortal>
        <video width="1110px" height="720px" controls>
            <source [src]="SELECTED_VID">
        </video>
    </form>
</swal>
<swal #openDocument showCloseButton="true" [showConfirmButton]="false" width="1200px" height="750px">
    <form *swalPortal>
        <h1>{{documentShow.title}}</h1>
        <p>{{documentShow.notes}}</p>
        <embed [src]="documentShow.fileLink | sanitize" type="application/pdf" width="100%" height="700px" />
    </form>
</swal>
