<div class="container-fluid">
<!-- Zona de filtros -->
<div id="filter" *ngIf="showFilter">
    <h3><img src="assets/img/embudo.png" width="32px" height="32px">Filtros</h3>
    <div class="row">
        <div class="col">
            <div class="form-check">
                <input [(ngModel)]="HEADERS[0]" class="form-check-input" type="checkbox" value="" id="code">
                <label class="form-check-label" for="code">
                Código
                </label>
                <input #cardcode (keyup)="test(cardcode.value); showFilter=!showFilter; showFilter=!showFilter;" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por código" aria-label="Buscar por código">
                
                <input [(ngModel)]="HEADERS[1]" class="form-check-input" type="checkbox" value="" id="description">
                <label class="form-check-label" for="description">
                Descripción
                </label>
                <input #description (keyup)="FILTER2=description.value;" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por descripción" aria-label="Buscar por descripción">
            
                <input [(ngModel)]="HEADERS[2]" class="form-check-input" type="checkbox" value="" id="group">
                <label class="form-check-label" for="group">
               Grupo
                </label>
                <input #group (keyup)="FILTER3=group.value;" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por grupo" aria-label="Buscar por grupo">
    
                <input [(ngModel)]="HEADERS[3]" class="form-check-input" type="checkbox" value="" id="prov">
                <label class="form-check-label" for="prov">
               Proveedor
                </label>
                <input #prov (keyup)="FILTER4=prov.value;" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por proveedor" aria-label="Buscar por proveedor">
            </div>
        </div>
        <div class="col">
            <div class="form-check">
            <input [(ngModel)]="HEADERS[4]" class="form-check-input" type="checkbox" value="" id="cost">
            <label class="form-check-label" for="cost">
            Costo
            </label>
            <br>
            <input [(ngModel)]="HEADERS[5]" class="form-check-input" type="checkbox" value="" id="inventario">
            <label class="form-check-label" for="inventario">
            Inventario
            </label>
            <br>
            <input [(ngModel)]="HEADERS[6]" class="form-check-input" type="checkbox" value="" id="inventario_s">
            <label class="form-check-label" for="inventario_s">
            Inventario_valor
            </label>
            <br>
            <input [(ngModel)]="HEADERS[7]" class="form-check-input" type="checkbox" value="" id="min_val">
            <label class="form-check-label" for="min_val">
            Minimo
            </label>
            <br>
            <input [(ngModel)]="HEADERS[8]" class="form-check-input" type="checkbox" value="" id="min_val_$">
            <label class="form-check-label" for="min_val_$">
            Valor_minimo
            </label>
            </div>
            <br>
            <p><button class="btn btn-primary" (click)="reload()">Recargar</button></p>
            <p><button class="btn btn-success" (click)="createCSV()">Exportar a CSV</button></p>
            <p><button class="btn btn-warning" (click)="FILTER1='';FILTER2='';FILTER3='';FILTER4='';FILTER5=''; this.restartRange();">Limpiar filtros</button></p>

        </div>
        <div class="col">
            <input [(ngModel)]="HEADERS[9]" class="form-check-input" type="checkbox" value="" id="ped_prov">
            <label class="form-check-label" for="ped_prov">
            Pedidos proveedores
            </label>
            <br>
            <input [(ngModel)]="HEADERS[10]" class="form-check-input" type="checkbox" value="" id="ped_clien">
            <label class="form-check-label" for="ped_clien">
            Pedidos clientes
            </label>
            <br>
            <input [(ngModel)]="HEADERS[11]" class="form-check-input" type="checkbox" value="" id="%">
            <label class="form-check-label" for="%">
            Porcentaje
            </label>
            <br>
            <input [(ngModel)]="HEADERS[12]" class="form-check-input" type="checkbox" value="" id="semaphore">
            <label class="form-check-label" for="semaphore">
            Semáforo
            </label>
            <br>
            <button (click)="FILTER5='RED';" style="background: red;" class="semaphore"></button> <button (click)="FILTER5='YELLOW'" style="background: yellow;" class="semaphore"></button> <button (click)="FILTER5='GREEN'" style="background: green;" class="semaphore"></button> <button (click)="FILTER5='BLACK'" style="background: black;" class="semaphore"></button><br> 
            
        </div>
        <div class="col">
            <!--Filtros de porcentaje-->
            <label class="form-check-label" for="range">Rango</label>
            <br>
            <div class="row">
                <div class="col">
                    <select #filter class="form-control">
                        <option value="" selected disabled hidden>...</option>
                        <option value="1">Inventario</option>
                        <option value="2">Minimo</option>
                        <option value="3">Pedidos clientes</option>
                        <option value="4">Pedidos proveedores</option>
                    </select>
                </div>
                <div class="col">
                    <select #condition class="form-control">
                        <option value="" selected disabled hidden>...</option>
                        <option value="!=">!=</option>
                        <option value="==">=</option>
                    </select>
                </div>
                <div class="col">
                    <input #percent class="form-control" type="number" value="0" (keyup)="conditional.value = percent.value" readonly id="percent">
                </div>
                <div class="col"><button #convert_button (click)="filterByRange(condition.value,filter.value);" type="button" class="btn btn-primary">Consultar</button></div>
                <p #alert1 class="alert1">Recuerda presionar "<b>Limpiar Filtros</b>"" para realizar otra consulta</p>
            </div>
        </div>
    </div>
</div>
<div class="custom-control custom-switch">
<input [(ngModel)]="showFilter" type="checkbox" class="custom-control-input" id="customSwitch1" checked>
<label class="custom-control-label" for="customSwitch1">{{showFilter ? 'Desactivar filtros' : 'Activar filtros'}}</label>
</div>

<!--Control de tabla-->
<div *ngIf="!JSONisLoaded else loaded" class="row text-center animated fadeIn">
    <div class="col">
        <i class="fa fa-sync fa-spin fa-5x"></i>
    </div>
  </div>
<ng-template #loaded>
    <br>
<div class="table-responsive">
<table class="table table-hover">
    <thead class="thead-per">
        <th *ngIf="HEADERS[0]">Código</th>
        <th *ngIf="HEADERS[1]">Descripción</th>
        <th *ngIf="HEADERS[2]">Grupo</th>
        <th *ngIf="HEADERS[3]">Proveedor</th>
        <th *ngIf="HEADERS[4]">Costo</th>
        <th *ngIf="HEADERS[5]">Inventario</th>
        <th *ngIf="HEADERS[6]">Inventario valor</th>
        <th *ngIf="HEADERS[7]">Mínimo</th>
        <th *ngIf="HEADERS[8]">Valor mínimo $</th>
        <th *ngIf="HEADERS[9]">Pedidos proveedores</th>
        <th *ngIf="HEADERS[10]">Pedidos clientes</th>
        <th *ngIf="HEADERS[11]">%</th>
        <th *ngIf="HEADERS[12]">Semáforo</th>
    </thead>
    <tbody>
        <tr *ngFor="let i of DATA | Filter : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5">
            <td *ngIf="HEADERS[0]">{{i.codigo}}</td>
            <td *ngIf="HEADERS[1]">{{i.descripcion}}</td>
            <td *ngIf="HEADERS[2]">{{i.grupo}}</td>
            <td *ngIf="HEADERS[3]">{{i.proveedor}}</td>
            <td *ngIf="HEADERS[4]">{{i.costo | number : '1.2-2'}}</td>
            <td *ngIf="HEADERS[5]">{{i.inventario | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[6]">{{i.inventario_valor | removeDecimals}}</td>
            <td *ngIf="HEADERS[7]">{{i.minimo| currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[8]">{{i.minimo_valor | removeDecimals}}</td>
            <td *ngIf="HEADERS[9]">{{i.pedidos_proveedores | removeDecimals}}</td>
            <td *ngIf="HEADERS[10]">{{i.pedidos_clientes | removeDecimals}}</td>
            <td *ngIf="HEADERS[11]">{{i.porcentaje | removeDecimals}}</td>
            <td *ngIf="HEADERS[12]"><div [style]="'background :' + i.semaforo + '; text-align: center;'" class="semaphore"></div></td>
        </tr>
        <tr id="totals">
            <td *ngIf="HEADERS[0]">Totales: </td>
            <td *ngIf="HEADERS[1]">{{DATA | Filter : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'total' | removeDecimals}}</td>
            <td *ngIf="HEADERS[2]"></td>
            <td *ngIf="HEADERS[3]"></td>
            <td *ngIf="HEADERS[4]">{{DATA | Filter : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'costo'| number : '1.2-2'}}</td>
            <td *ngIf="HEADERS[5]">{{DATA | Filter : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'inventario' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[6]">${{DATA | Filter : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'inventario_valor' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[7]">{{DATA | Filter : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'minimo' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[8]">${{DATA | Filter : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'minimo_valor' | currency : 'USD' : '' : '1.0-0'}}</td>
            <td *ngIf="HEADERS[9]">{{DATA | Filter : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'pedidos_proveedores'}}</td>
            <td *ngIf="HEADERS[10]">{{DATA | Filter : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum': 'pedidos_clientes'}}</td>
            <td *ngIf="HEADERS[11]">{{DATA | Filter : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'porcentaje'}}</td>
            <td *ngIf="HEADERS[12]"></td>
        </tr>
    </tbody>
</table>
</div>

<textarea id="hidden_textarea">{{DATA | Filter : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5 | json}}</textarea>
</ng-template>
</div>