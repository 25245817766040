import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterC'
})

export class FilterPipeC implements PipeTransform {
  transform(items: any[], searchText2: string, column: string): any[] {
    if (!items) return [];
    if (!searchText2) return items;
    searchText2 = searchText2.toLowerCase();
    return items.filter(item => {
      const columnValue = item[column] ? item[column].toString().toLowerCase() : '';
      return columnValue.includes(searchText2);
    });
  }
}
