<div class="row" *ngIf="!isLogged">
    <div class="col"></div>
    <div class="col">
        <div *ngIf="failToLogin"class="alert alert-danger" role="alert">
            <h3>Error</h3>
            {{error_message}}
        </div>
        <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
        <h3>Correo</h3>
        <input type="text" name="email" required email [(ngModel)]="user.email" class="form-control">
        <span *ngIf="loginForm.submitted && loginForm.controls['email'].errors"
                          class="text-danger animated fadeIn">Ingresa tu email</span>
        <h3>Password</h3>
        <input type="password" name="password" required password [(ngModel)]="user.password" class="form-control">
        <span *ngIf="loginForm.submitted && loginForm.controls['password'].errors"
                          class="text-danger animated fadeIn">Ingresa tu password</span>
        <br>
        <button type="submit" class="btn btn-success btn-lg btn-block">Iniciar sesión</button>
        <br>
        <a routerLink="../register">¿No tienes cuenta? regístrate</a>
        <br>
        <a routerLink="../forgotPassword">¿Olvidaste tu contraseña?</a>
        <br>
    </form>
    </div>
    <div class="col"></div>
</div>
<div class="row" *ngIf="isLogged">
    <div class="col"></div>
    <div class="col">
        <h3>Error:</h3>
        <p>Usted se encuentra registrado</p>
    </div>
    <div class="col"></div>
</div>