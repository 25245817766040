import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterS'
})

export class FilterPipeS implements PipeTransform {
  transform(items: any[], searchText4: string, column: string): any[] {
    if (!items) return [];
    if (!searchText4) return items;
    searchText4 = searchText4.toLowerCase();
    return items.filter(item => {
      const columnValue = item[column] ? item[column].toString().toLowerCase() : '';
      return columnValue.includes(searchText4);
    });
  }
}
