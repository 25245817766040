<div class="container">
    <div id="banner" [class]="'alert '+alert_type" *ngIf="DONE">
        <h2>{{MSG_TEXT[0]}}</h2>
        <p>{{MSG_TEXT[1]}}</p>
    </div>
    <h3>Registrar un producto faltante</h3>
<form (ngSubmit)="sendJSON();">
    <div class="row">
        <div class="col"><label>Código de cliente</label><input type="text" [(ngModel)]="row.cardCode" name="cardCode" autocompleteOff class="form-control" required></div>
        <div class="col"><label>Nombre del cliente</label><input required type="text" [(ngModel)]="row.cardName" name="cardName" autocompleteOff class="form-control"></div>
    </div>
    <div class="row">
        <div class="col"><label>Dirección</label><input type="text" [(ngModel)]="row.address" name="address" autocompleteOff class="form-control"></div>
        <div class="col"><label>Codigo de producto SAP</label><input required type="text" [(ngModel)]="row.itemCode" autocompleteOff name="itemNo" class="form-control"></div>
    </div>
    <div class="row">
        <div class="col"><label>Código de producto de cliente</label><input required type="text" [(ngModel)]="row.freeTxt" autocompleteOff name="freeTxt" class="form-control"></div>
        <div class="col"><label>Descripción</label><input type="text" [(ngModel)]="row.description" name="description" autocompleteOff class="form-control"></div>
    </div>
    <div class="row">
        <div class="col"><label>Importe</label><input required type="number" [(ngModel)]="row.price" name="precio" class="form-control"></div>
        <div class="col"><label>Moneda</label>
            <select class="form-control" required [(ngModel)]="row.currency" name="currency">
                <option>MN</option>
                <option>USD</option>
                <option>EUR</option>
            </select>
        </div>
    </div>
    <br>
    <input type="submit" [class]="!OVERRIDE_VALUE?'btn btn-primary':'btn btn-warning'" [value]="!OVERRIDE_VALUE?'Añadir producto':'Actualizar producto'"> <button  *ngIf="OVERRIDE_VALUE" type="button" class="btn btn-danger" (click)="OVERRIDE_VALUE=false; cleanForm();">Cancelar</button>
</form>
<hr>

<div class="table-wrapper" *ngIf="INFO.length>0">
    <table class="table table-hover table-sm">
        <thead class="thead-per" style="position: sticky;top: 0">
            <tr>
                <th>Código de producto de cliente
                    <input class="inputD" type="text" [(ngModel)]="searchValue" placeholder="Código de Producto">

                </th>
                
                <th>Código del cliente
                    <input class="inputD"type="text" [(ngModel)]="searchValue2" placeholder="Código del Cliente">
                </th>
                <th>Nombre del cliente
                    <input class="inputD" type="text" [(ngModel)]="searchValue3" placeholder="Nombre del Cliente">

                </th>
                
                <th>Código de prod. SAP
                    <input class="inputD" type="text" [(ngModel)]="searchValue4" placeholder="Código del prod. SAP">

                </th>
                <th>Descripción
                </th>
                <th>Dirección
                </th>
                <th>Importe
                </th>
                <th>Divisa
                </th>
                <th>Opciones
                </th>
            </tr>
        </thead>
        <tbody>

            <tr *ngFor="let i of INFO | filterConfig:searchValue:'freetxt'| filterC:searchValue2:'cardcode'| filterN:searchValue3:'cardname'| filterS:searchValue4:'itemcode'; let in = index;" (click)="fillForUpdate(i);">
                <td>{{i.freetxt}}</td>
                <td>{{i.cardcode}}</td>
                <td>{{i.cardname}}</td>
                <td>{{i.itemcode}}</td>
                <td>{{i.description}}</td>
                <td>{{i.address}}</td>
                <td>{{i.price}}</td>
                <td>{{i.currency}}</td>
                <td>
                    <button type="button" (click)="deleteProduct(i.freetxt);" class="btn btn-danger"><img src="/assets/img/table/delete.png" width="24px" height="24px"></button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<br>
<p>v.2.0</p>
</div>