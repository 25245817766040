import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'forecast'
})
export class ForecastPipe implements PipeTransform {
  DATA = [];
  getKeySum(key:string){
    const res = this.DATA.map((x)=>x[key]).reduce((a,b)=>a+b);
    return res;
  }

  transform(datos:any,headers:any): unknown {
    this.DATA = datos;
    let response = [];
    for(const i of headers){
      if (i!='code'){
        response.push(this.getKeySum(i));
      }
    }
    return response;
  }
}
