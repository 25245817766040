import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {API} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SemaphoreService {

  constructor(private http:HttpClient) { }
  getSemaphore(filter : string){
    return this.http.get(API+'/semaphore'+filter,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  });
  }
  getMape(filter:string){
    return this.http.get(API+'/mape'+filter,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  });
  }
  getForecast(scope:string){
    return this.http.get(`${API}/forecast?scope=${scope}`,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  });
  }
  postForecast(body:any){
    return this.http.post(`${API}/forecast/orders`,body,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  });
  }
}

