<div class="container-fluid">
<!-- Zona de filtros -->
<div id="filter" *ngIf="showFilter">
    <h3><img src="assets/img/embudo.png" width="32px" height="32px">Filtros</h3>
    <div class="row">
        <div class="col">
            <div class="form-check">


                <div class="col">
                    <!--Filtros de porcentaje-->
                    <label class="form-check-label" for="range">Consulta Personalizada</label>
                    <br>
                    <div class="row">
                        <div class="col">
                    
        
                            <select #filter class="form-control">
                                <option value="1">FECHA</option>
                                <option value="2">PROVEEDOR</option>
                                <option value="3">FECHA Y PROVEEDOR</option>
                                <option value="4">ALMACÉN</option>
                                <option value="5">FECHA Y ALMACÉN</option>
                            </select>
                            <br>

        
                             <input #datei class="form-control" id="finicial" type="text" placeholder="Fecha Inicial"
                             onfocus="(this.type='date')">
                             <input #datef class="form-control" id="ffinal" type="text" placeholder="Fecha Final"
                             onfocus="(this.type='date')">
                             <br>

                            <label class="form-check-label" for="description">
                            PROVEEDOR
                            </label>
                            
                            <input #proveedorB style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Consultar por proveedor" aria-label="Buscar por descripción">
                            <div class="col"></div>
                            <br>

                            <label class="form-check-label" for="description">
                                ALMACÉN
                            </label>

                            <select #warehose class="form-control">
                                <option value="01">FAT</option>
                                <option value="02">FSS</option>
                            </select>
                            <br>

                            <p><button #convert_button (click)="filterByRange(filter.value,datei.value,datef.value,proveedorB.value,warehose.value);" type="button" class="btn btn-primary">Consultar</button>
                            </p>

                        </div>
    
                         <p #alert1 class="alert1">Recuerda presionar "<b>Limpiar Filtros</b>"" para realizar otra consulta</p> 
                    </div>
                </div>

                <!-- <input [(ngModel)]="HEADERS[1]" class="form-check-input" type="checkbox" value="" id="code">
                <label class="form-check-label" for="proveedor">
                    Proveedor
                </label>
                <input #cardcode (keyup)="test(cardcode.value); showFilter=!showFilter; showFilter=!showFilter;" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por código" aria-label="Buscar por código">
                
                <input [(ngModel)]="HEADERS[2]" class="form-check-input" type="checkbox" value="" id="description">
                <label class="form-check-label" for="description">
                PROVEEDOR
                </label> -->
                <!-- <input #description (keyup)="FILTER2=description.value;" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por descripción" aria-label="Buscar por descripción"> -->
<!--             
                <input [(ngModel)]="HEADERS[2]" class="form-check-input" type="checkbox" value="" id="rfcProveedor">
                <label class="form-check-label" for="rfcProveedor">
                 RFC
                </label> -->
                <!-- <input #group (keyup)="FILTER3=group.value;" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por grupo" aria-label="Buscar por grupo"> -->
                
                </div>
        </div>
        <div class="col">
            <div class="form-check">
                <input [(ngModel)]="HEADERS[1]" class="form-check-input" type="checkbox" value="" id="code">
                    <label class="form-check-label" for="proveedor">
                        Proveedor
                    </label>
                <input #cardcode (keyup)="test(cardcode.value); showFilter=!showFilter; showFilter=!showFilter;" style="color: red;" name="idForSearch" class="form-control" type="text" placeholder="Buscar por proveedor" aria-label="Buscar por código">
                
                <input [(ngModel)]="HEADERS[2]" class="form-check-input" type="checkbox" value="" id="cost">
                    <label class="form-check-label" for="cost">
                        VALOR EN M.N
                    </label>
                <br>

                <input [(ngModel)]="HEADERS[3]" class="form-check-input" type="checkbox" value="" id="cost">
                    <label class="form-check-label" for="cost">
                        IVA
                    </label>
                <br>

                <input [(ngModel)]="HEADERS[4]" class="form-check-input" type="checkbox" value="" id="cost">
                    <label class="form-check-label" for="cost">
                        HONORARIOS
                    </label>
                <br>
                <input [(ngModel)]="HEADERS[5]" class="form-check-input" type="checkbox" value="" id="inventario">
                    <label class="form-check-label" for="inventario">
                        FLETES
                    </label>
                <br>
                <input [(ngModel)]="HEADERS[6]" class="form-check-input" type="checkbox" value="" id="inventario_s">
                    <label class="form-check-label" for="inventario_s">
                        DTA
                    </label>
                <br>
                <input [(ngModel)]="HEADERS[7]" class="form-check-input" type="checkbox" value="" id="min_val">
                    <label class="form-check-label" for="min_val">
                        PRV
                    </label>
                <br>
                <input [(ngModel)]="HEADERS[8]" class="form-check-input" type="checkbox" value="" id="min_val_$">
                    <label class="form-check-label" for="min_val_$">
                     CNT
                    </label>

            </div>
            <br>
            <p><button class="btn btn-primary" (click)="reload()">Recargar</button></p>
            <p><button class="btn btn-success" (click)="createCSV()">Exportar a CSV</button></p>
            <!-- <p><button class="btn btn-warning" (click)="FILTER1='';FILTER2='';FILTER3='';FILTER4='';FILTER5=''; this.restartRange();">Limpiar filtros</button></p> -->

        </div>
        <div class="col">

            <input [(ngModel)]="HEADERS[9]" class="form-check-input" type="checkbox" value="" id="min_val">
                <label class="form-check-label" for="min_val">
                    IGI
                </label>
            <br>
            <input [(ngModel)]="HEADERS[10]" class="form-check-input" type="checkbox" value="" id="min_val">
                <label class="form-check-label" for="min_val">
                    GTS GRL AD
                </label>
            <br>

            <input [(ngModel)]="HEADERS[11]" class="form-check-input" type="checkbox" value="" id="min_val">
                <label class="form-check-label" for="min_val">
                    TOTAL GASTOS
                </label>
            <br>

            <input [(ngModel)]="HEADERS[12]" class="form-check-input" type="checkbox" value="" id="min_val">
                <label class="form-check-label" for="min_val">
                    COSTO TOTAL
                </label>
            <br>

            <input [(ngModel)]="HEADERS[13]" class="form-check-input" type="checkbox" value="" id="min_val">
                <label class="form-check-label" for="min_val">
                    #IMP
                </label>
            <br>
            <input [(ngModel)]="HEADERS[14]" class="form-check-input" type="checkbox" value="" id="min_val">
                <label class="form-check-label" for="min_val">
                    FI
                </label>
            <br>
            <input [(ngModel)]="HEADERS[15]" class="form-check-input" type="checkbox" value="" id="min_val">
                <label class="form-check-label" for="min_val">
                    PEDIMENTO
                </label>
            <br>

            <input [(ngModel)]="HEADERS[16]" class="form-check-input" type="checkbox" value="" id="code">
                <label class="form-check-label" for="proveedor">
                    FECHA PEDIMENTO
                </label>
                <br>

            <input [(ngModel)]="HEADERS[17]" class="form-check-input" type="checkbox" value="" id="min_val">
                <label class="form-check-label" for="min_val">
                    VALOR ADUANA
                </label>
            <br>
            <input [(ngModel)]="HEADERS[18]" class="form-check-input" type="checkbox" value="" id="min_val">
                <label class="form-check-label" for="min_val">
                    PERIODO
                </label>
            <br>

            <input [(ngModel)]="HEADERS[19]" class="form-check-input" type="checkbox" value="" id="min_val">
                <label class="form-check-label" for="min_val">
                    COMENTARIOS
                </label>
            <br>
            
            
        </div>
       
    </div>
</div>
<div class="custom-control custom-switch">
    <input [(ngModel)]="showFilter" type="checkbox" class="custom-control-input" id="customSwitch1" checked>
    <label class="custom-control-label" for="customSwitch1">{{showFilter ? 'Desactivar filtros' : 'Activar filtros'}}</label>
</div>

<!--Control de tabla-->
<div *ngIf="!JSONisLoaded else loaded" class="row text-center animated fadeIn">
    <div class="col">
        <i class="fa fa-sync fa-spin fa-5x"></i>
    </div>
  </div>
<ng-template #loaded>
    <br>
<div class="table-responsive tableFixHead">
<table *ngIf="JSONisLoaded" class="table table-hover table-container222 " >
    <thead class="thead-per " >
        <tr class="">
        <th class="formHeaders" *ngIf="HEADERS[1]">PROVEEDOR</th>
        <th class="formHeaders" *ngIf="HEADERS[2]">VALOR EN M.N.</th>
        <th class="formHeaders" *ngIf="HEADERS[3]">IVA</th>
        <th class="formHeaders" *ngIf="HEADERS[4]">HONORARIOS </th>
        <th class="formHeaders" *ngIf="HEADERS[5]">FLETES</th>
        <th class="formHeaders" *ngIf="HEADERS[6]">DTA</th>
        <th class="formHeaders" *ngIf="HEADERS[7]">PRV</th>
        <th class="formHeaders" *ngIf="HEADERS[8]">CNT</th>
        <th class="formHeaders" *ngIf="HEADERS[9]">IGI</th>
        <th class="formHeaders" *ngIf="HEADERS[10]">GTS GRL AD</th>
        <th class="formHeaders" *ngIf="HEADERS[11]">TOTAL GASTOS</th>
        <th class="formHeaders" *ngIf="HEADERS[12]">COSTO TOTAL</th>
        <th class="formHeaders" *ngIf="HEADERS[13]">#IMP</th>
        <th class="formHeaders" *ngIf="HEADERS[14]">FI</th>
        <th class="formHeaders" *ngIf="HEADERS[15]">PEDIMENTO</th>
        <th class="formHeaders" *ngIf="HEADERS[16]">FECHA</th>
        <th class="formHeaders" *ngIf="HEADERS[17]">VALOR ADUANA</th>
        <th class="formHeaders" *ngIf="HEADERS[18]">PERIODO</th>
        <th class="formHeaders" *ngIf="HEADERS[19]">COMENTARIOS</th>


    </tr>

    </thead>
    <tbody class="paindingThead">
        <tr *ngFor="let i of DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5 ">
            <td *ngIf="HEADERS[1]">{{i.PROVEEDOR}}</td>
            <td class="formMoney" *ngIf="HEADERS[2]">{{i?.VALOR_EN_MN == null? "$0.00" :i?.VALOR_EN_MN | currency: 0 : "$"}}</td>
            <td class="formMoney" *ngIf="HEADERS[3]">{{i?.IVA == null? "$0.00" :i?.IVA | currency: 0 : "$" }}</td>
            <td class="formMoney" *ngIf="HEADERS[4]">{{i?.HONORARIOS == null? "$0.00":i?.HONORARIOS | currency: 0 : "$"}}</td>
            <td class="formMoney" *ngIf="HEADERS[5]">{{i?.FLETES == null? "$0.00":i?.FLETES | currency: 0 : "$"}}</td>
            <td class="formMoney" *ngIf="HEADERS[6]">{{i?.DTA == null? "$0.00":i?.DTA | currency: 0 : "$"}}</td>
            <td class="formMoney" *ngIf="HEADERS[7]">{{i?.PRV == null? "0.00":i?.PRV | currency: 0 : "$" }}</td>
            <td class="formMoney" *ngIf="HEADERS[8]">{{i?.CNT == null? "0.00":i?.CNT | currency: 0 : "$"}}</td>
            <td class="formMoney" *ngIf="HEADERS[9]">{{i?.IGI == null? "0.00":i?.IGI | currency: 0 : "$"}}</td>
            <td class="formMoney" *ngIf="HEADERS[10]">{{i?.GTS_GRL_AD== null? "0.00":i?.GTS_GRL_AD | currency: 0 : "$"}}</td>
            <td class="formMoney" *ngIf="HEADERS[11]">{{i?.TOTAL_GASTOS == null? "0.00":i?.TOTAL_GASTOS | currency: 0 : "$"}}</td>
            <td class="formMoney" *ngIf="HEADERS[12]">{{i?.COSTO_TOTAL == null? "0.00":i?.COSTO_TOTAL | currency: 0 : "$"}}</td>
            <td class="formMoney" *ngIf="HEADERS[13]">{{i?.IMP == null? "0":i?.IMP}}</td>
            <td class="formMoney" *ngIf="HEADERS[14]">{{i?.FI == null? "0.00%":i?.FI+"%"}}</td>
            <td class="formMoney" *ngIf="HEADERS[15]">{{i?.PEDIMENTO == null? "0.00":i?.PEDIMENTO}}</td>
            <td class="formMoney" *ngIf="HEADERS[16]">{{i?.FECHA_PEDIMENTO}}</td>
            <td class="formMoney" *ngIf="HEADERS[17]">{{i?.VALOR_ADUANA == null? "0.00":i?.VALOR_ADUANA | number: '1.2-2'}}</td>
            <td class="formMoney" *ngIf="HEADERS[18]">{{i?.PERIODO}}</td>
            <td class="formMoney" *ngIf="HEADERS[19]">{{i?.COMENTARIOS}}</td>



        </tr>
        <tr id="totals" >
            <td *ngIf="HEADERS[1]">Totales: </td>

            <td class="formMoney" *ngIf="HEADERS[2]">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'VALOR_EN_MN' | currency: 0 : "$" }}</td>
            <td class="formMoney" *ngIf="HEADERS[3]">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'IVA' | currency: 0 : "$" }}</td>
            <td class="formMoney" *ngIf="HEADERS[4]">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'HONORARIOS'  | currency: 0 : "$"}}</td>
            <td class="formMoney" *ngIf="HEADERS[5]">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'FLETES' | currency: 0 : "$" }}</td>
            <td class="formMoney" *ngIf="HEADERS[6]">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'DTA' | currency: 0 : "$" }}</td>
            <td class="formMoney" *ngIf="HEADERS[7]">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'PRV' | currency: 0 : "$" }}</td>
            <td class="formMoney" *ngIf="HEADERS[8]">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'CNT' | currency: 0 : "$" }}</td>
            <td class="formMoney" *ngIf="HEADERS[9]">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'IGI' | currency: 0 : "$" }}</td>
            <td class="formMoney" *ngIf="HEADERS[10]">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'GTS_GRL_AD' | currency: 0 : "$"}}</td>
            <td class="formMoney" *ngIf="HEADERS[11]">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'TOTAL_GASTOS' | currency: 0 : "$"}}</td>
            <td class="formMoney" *ngIf="HEADERS[12]">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'COSTO_TOTAL' | currency: 0 : "$"}}</td>
            <td class="formMoney" *ngIf="HEADERS[13]"></td>
            <td class="formMoney" *ngIf="HEADERS[14]"></td>
            <td class="formMoney" *ngIf="HEADERS[15]"></td>
            <td class="formMoney" *ngIf="HEADERS[16]"></td>
            <td class="formMoney" *ngIf="HEADERS[17]"></td>
            <td class="formMoney" *ngIf="HEADERS[18]"></td>

            
            <td *ngIf="HEADERS[17]"></td>
        </tr>

        <tr id="totals" >
            <td *ngIf="HEADERS[1]">IMPO:: </td> 
            <td class="formMoney" *ngIf="HEADERS[2]">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'sum':'VALOR_EN_MN' | currency: 0 : "$" }}</td>
            <td *ngIf="HEADERS[3]"></td>
            <td *ngIf="HEADERS[4]"></td>
            <td *ngIf="HEADERS[5]"></td>
            <td *ngIf="HEADERS[6]"></td>
            <td *ngIf="HEADERS[7]"></td>
            <td *ngIf="HEADERS[8]"></td>
            <td class="formMoney" *ngIf="HEADERS[9]">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5:'pocientosum':'FI' | percent: '2.1-2'}}</td>
        
            
            <td *ngIf="HEADERS[17]"></td>
        </tr>
    </tbody>
</table>
</div>

<textarea id="hidden_textarea">{{DATA | FilterFactor : showFilter : 1 :FILTER1:FILTER2:FILTER3:FILTER4:FILTER5 | json}}</textarea>
</ng-template>
</div>