import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDecimals'
})
export class RemoveDecimalsPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    const aux = value.toString().split(".");
    if(args[0]==true){
      return aux[0].substring(1,aux[0].length);
    } else if(args[0]==='%'){
        const percent = value.toString().split('%');
        return Math.floor(Number(percent[0]))+'%';
    } else {
      if(aux[0].indexOf(',')>-1){
        return aux[0];
      } else {
        return aux[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  }

}
