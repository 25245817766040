import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {APIN} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FactorService {

  constructor(private http:HttpClient) { }
  getImportFactor(filter:string){
    return this.http.get(APIN+'/factorimportacion'+filter,{
      headers:{
      'Authorization':"Bearer "+localStorage.getItem('token'),
      "Content-Type": "application/json",
    }
  });
  }



 
}
