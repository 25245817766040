import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { createSemaphoreCSV } from 'src/app/models/createSemaphoreCSV';
import { SemaphoreService } from 'src/app/services/semaphore.service';

@Component({
  selector: 'app-semaphore',
  templateUrl: './semaphore.component.html',
  styleUrls: ['./semaphore.component.css']
})
export class SemaphoreComponent implements OnInit {
  HEADERS:boolean[] = [true,true,true,true,true,true,true,true,true,true,true,true,true];
  FILTER1:string = '';FILTER2:string = ''; FILTER3:string = ''; FILTER4:string = ''; FILTER5:string = '';
  DATA = [];
  showFilter:boolean = false;
  URL_FILTER = '';
  JSONisLoaded:boolean = false;
  constructor(private s:SemaphoreService,private r:Router) { }
  test(val:string):void{ this.FILTER1 = val;}
  restartRange():void{this.URL_FILTER = ''; this.updateTable();}
  filterByRange(condition:string,filter:string):void{
    this.URL_FILTER = '?filtro=';
    switch(Number(filter)){
      case 1 : this.URL_FILTER+='inventario'; break;
      case 2 : this.URL_FILTER+='minimo'; break;
      case 3 : this.URL_FILTER+='p_clientes'; break;
      case 4 : this.URL_FILTER+='p_proveedores'; break;
    }
    if(condition==='!='){
      this.URL_FILTER+='_mayor_cero';
    } else {
      this.URL_FILTER+='_igual_cero';
    }
    this.updateTable();
  }
  reload(){this.r.navigateByUrl('/semaphore').then(()=> window.location.reload());}

  updateTable(){
    this.s.getSemaphore(this.URL_FILTER).subscribe((res)=>{
        this.DATA = res["body"];
        this.JSONisLoaded = true;
        
    })
  }
  createCSV(){
    const csv = new createSemaphoreCSV(this.HEADERS);
    csv.exportCSV();
  }
  ngOnInit(): void {
    this.updateTable();
  }

}
