import { Console } from 'console';
import * as FileSaver from 'file-saver';
export class createFactorCSV{
    HEADERS:boolean[];

    createCSV(data){

      var json = data;
        const cabeceras=[
          this.HEADERS[1]==true?"PROVEEDOR":"",
          this.HEADERS[2]==true?"VALOR_EN_MN":"",
          this.HEADERS[3]==true?"IVA":"",
          this.HEADERS[4]==true?"HONORARIOS":"",
          this.HEADERS[5]==true?"FLETES":"",
          this.HEADERS[6]==true?"DTA":"",
          this.HEADERS[7]==true?"PRV":"",
          this.HEADERS[8]==true?"CNT":"",
          this.HEADERS[9]==true?"IGI":"",
          this.HEADERS[10]==true?"GTS_GRL_AD":"",
          this.HEADERS[11]==true?"TOTAL_GASTOS":"",
          this.HEADERS[12]==true?"COSTO_TOTAL":"",
          this.HEADERS[13]==true?"IMP":"",
          this.HEADERS[14]==true?"FI":"",
          this.HEADERS[15]==true?"PEDIMENTO":"",
          this.HEADERS[16]==true?"FECHA_PEDIMENTO":"",
          this.HEADERS[17]==true?"VALOR_ADUANA":"",
          this.HEADERS[18]==true?"PERIODO":"",
          this.HEADERS[19]==true?"COMENTARIOS":""
          ]

          const jsonHeaders = cabeceras.filter(headers => headers !=="");


        
        var fields = Object.keys(json[0])
        console.log(fields);

        var replacer = function(key, value) { return value === null ? '' : value } 
        var csv = json.map(function(row){
        return jsonHeaders.map(function(fieldName){
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
      })
      csv.unshift(jsonHeaders.join(',')) // add header column
      csv = csv.join('\r\n');
      return csv;
      }
    filterCSV(json):any{
        for(const i in json){

          // if(this.HEADERS[1]==false){delete json[i]['PROVEEDOR'];}
          // if(this.HEADERS[2]==false){delete json[i]['VALOR_EN_MN'];}
          // if(this.HEADERS[3]==false){delete json[i]['IVA'];}
          // if(this.HEADERS[4]==false){delete json[i]['HONORARIOS'];}
          // if(this.HEADERS[5]==false){delete json[i]['FLETES'];}
          // if(this.HEADERS[6]==false){delete json[i]['DTA'];}
          // if(this.HEADERS[7]==false){delete json[i]['PRV'];}
          // if(this.HEADERS[8]==false){delete json[i]['CNT'];}
          // if(this.HEADERS[9]==false){delete json[i]['IGI'];}
          // if(this.HEADERS[10]==false){delete json[i]['GTS_GRL_AD'];}
          // if(this.HEADERS[11]==false){delete json[i]['TOTAL_GASTOS'];}
          // if(this.HEADERS[12]==false){delete json[i]['COSTO_TOTAL'];}

          // if(this.HEADERS[13]==false){delete json[i]['IMP'];}
          // if(this.HEADERS[14]==false){delete json[i]['FI'];}
          // if(this.HEADERS[15]==true){delete json[i]['PEDIMENTO'];}
          // if(this.HEADERS[16]==true){delete json[i]['VALOR_ADUANA'];}
          // if(this.HEADERS[17]==true){delete json[i]['PERIODO'];}
          // if(this.HEADERS[18]==true){delete json[i]['COMENTARIOS'];}

         
        }
    return json
    }



   
      convertToNum(val:any):number{
        if(isNaN(val)){ val = Number(val.replace(/\%|\$|\,/g,"")) } else if (val===''){val=0}
        return val
      }

    exportCSV():void{
        let json = JSON.parse((<HTMLInputElement>document.getElementById("hidden_textarea")).value);

        const nJson= json.map(item => {
          const nJsonExport=
            {
              "PROVEEDOR": item.PROVEEDOR,
              "VALOR_EN_MN":Number(item.VALOR_EN_MN),
              "IVA": Number(item.IVA),
              "HONORARIOS": Number(item.HONORARIOS),
              "FLETES": Number(item.FLETES),
              "DTA": Number(item.DTA),
              "PRV": Number(item.PRV),
              "CNT": Number(item.CNT),
              "IGI": Number(item.IGI),
              "GTS_GRL_AD": Number(item.GTS_GRL_AD),
              "TOTAL_GASTOS": Number(item.TOTAL_GASTOS),
              "COSTO_TOTAL": Number(item.COSTO_TOTAL),
              "IMP":item.IMP,
              "FI": (item?.FI)?.toFixed(2),
              "PEDIMENTO":item.PEDIMENTO,
              "FECHA_PEDIMENTO": item.FECHA_PEDIMENTO,
              "VALOR_ADUANA": item.VALOR_ADUANA,
              "PERIODO": item.PERIODO,
              "COMENTARIOS": item.COMENTARIOS,
            }
          return nJsonExport;
      })

        const VALOR_EN_MN = json.map((x)=>this.convertToNum(x["VALOR_EN_MN"])).reduce((prev,next) => prev + next);
        const IVA = json.map((x)=>this.convertToNum(x["IVA"])).reduce((prev,next) => prev + next);
        const HONORARIOS = json.map((x)=>this.convertToNum(x["HONORARIOS"])).reduce((prev,next) => prev + next);
        const FLETES = json.map((x)=>this.convertToNum(x["FLETES"])).reduce((prev,next) => prev + next);
        const DTA = json.map((x)=>this.convertToNum(x["DTA"])).reduce((prev,next) => prev + next);
        const PRV = json.map((x)=>this.convertToNum(x["PRV"])).reduce((prev,next) => prev + next);
        const CNT = json.map((x)=>this.convertToNum(x["CNT"])).reduce((prev,next) => prev + next);
        const IGI = json.map((x)=>this.convertToNum(x["IGI"])).reduce((prev,next) => prev + next);
        const GTS_GRL_AD = json.map((x)=>this.convertToNum(x["GTS_GRL_AD"])).reduce((prev,next) => prev + next);
        const TOTAL_GASTOS = json.map((x)=>this.convertToNum(x["TOTAL_GASTOS"])).reduce((prev,next) => prev + next);
        const COSTO_TOTAL = json.map((x)=>this.convertToNum(x["COSTO_TOTAL"])).reduce((prev,next) => prev + next);
        const IMP = json.map((x)=>this.convertToNum(x["IMP"])).reduce((prev,next) => prev + next);
        const FI = json.map((x)=>this.convertToNum(x["FI"])).reduce((prev,next) => prev + next);
        const PEDIMENTO = json.map((x)=>this.convertToNum(x["PEDIMENTO"])).reduce((prev,next) => prev + next);
        const VALOR_ADUANA = json.map((x)=>this.convertToNum(x["VALOR_ADUANA"])).reduce((prev,next) => prev + next);

        const TOTALES=
        
            {
              "PROVEEDOR": "TOTALES:",
              "VALOR_EN_MN": Number(VALOR_EN_MN),
              "IVA": Number(IVA),
              "HONORARIOS": Number(HONORARIOS),
              "FLETES": Number(FLETES),
              "DTA": Number(DTA),
              "PRV": Number(PRV),
              "CNT": Number(CNT),
              "IGI": Number(IGI),
              "GTS_GRL_AD": Number(GTS_GRL_AD),
              "TOTAL_GASTOS": Number(TOTAL_GASTOS),
              "COSTO_TOTAL": Number(COSTO_TOTAL),
              "IMP": "",
              "FI":"",
              "PEDIMENTO": "",
              "VALOR_ADUANA": "",
              "PERIODO": "",
              "COMENTARIOS": "",
            };


            const TOTALESG=
        
            {
              "PROVEEDOR": "IMPO:",
              "VALOR_EN_MN": Number(VALOR_EN_MN),
              "IGI": (TOTAL_GASTOS/VALOR_EN_MN).toFixed(2)+"%",
            }
          

            nJson[json.length]=TOTALES;
            nJson[json.length+1]=TOTALESG;

        console.log(nJson)
        json = this.filterCSV(nJson);
        const data: Blob = new Blob([this.createCSV(json)], { type: 'text/plain;charset=utf-8' });

        console.log(data);
        const fileName:string = 'Factor-Importación-Bollhoff '+new Date().getFullYear()+'-'+new Date().getDay()+'-'+new Date().getMonth()+':'+new Date().getHours()+'.'+new Date().getMinutes()+'.'+new Date().getSeconds()+'.csv'
        FileSaver.saveAs(data, fileName);

    }
    constructor(headers:boolean[]){
        this.HEADERS = headers;

    }
}