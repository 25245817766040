import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterConfig'
})

export class FilterPipe003 implements PipeTransform {
  transform(items: any[], searchText: string, column: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(item => {
      console.log(item);
      const columnValue = item[column] ? item[column].toString().toLowerCase() : '';
      return columnValue.includes(searchText);
    });
  }
}
