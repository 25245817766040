import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Filter02'
})
export class Filtradorv3Pipe02 implements PipeTransform {
  DATA:any[] = [];
  obtieneSuma(key:string):number{
    if (key==="total"){
      return this.DATA.length;
    }
    else if(this.DATA.length>0){
      const data = this.DATA.map((x)=>this.convertToNum(x[key])).reduce((prev,next) => prev + next);
      return data;
    } else {
      return 0;
    }
  }
  convertToNum(val:any):number{
    if(isNaN(val)){ val = Number(val.replace(/\%|\$|\,/g,"")) } else if (val===''){val=0}
    return val
  }
  transform(data: any, ...args:any[]): any {
    this.DATA = data;
    let post = [];
    if(args[0]==true){
      switch(args[1]){
        case 1:
          post = data.filter(x=>x['codeProv'].toLowerCase().startsWith(args[2].toLowerCase()));
         
          this.DATA = post;
        break;
        case 2:
          post = data.filter(x=>x['Codigo'].toLowerCase().startsWith(args[2].toLowerCase()));
          post = post.filter(x=>x['Descripcion'].toLowerCase().startsWith(args[3].toLowerCase()));
          post = post.filter(x=>x['Grupo'].toLowerCase().startsWith(args[4].toLowerCase()));
          if(args[5]!=""){
            post = post.filter(x=>x['ABC'] == args[5])
          }
          this.DATA = post;
        break;
      }
      if(args[7]==="sum"){return this.obtieneSuma(args[8]).toFixed(2);}else{return this.DATA;}
    } 
      else {
        if(args[7]==="sum"){return this.obtieneSuma(args[8]).toFixed(2);}else{return this.DATA;}
    }
  }

}
