import { Component, OnInit } from '@angular/core';
import { ReporteService } from 'src/app/services/reporte.service';

@Component({
  selector: 'app-config-pedidos',
  templateUrl: './config-pedidos.component.html',
  styleUrls: ['./config-pedidos.component.css']
})
export class ConfigPedidosComponent implements OnInit {
  OVERRIDE_VALUE:boolean = false;
  OVERRIDE_FREETXT:string = '';
  alert_type:string = '';
  DONE:boolean = false;
  MSG_TEXT:any = ['',''];
  INFO:any = [];
  row:any = {cardCode: "",cardName:"",address:"",itemCode:"",freeTxt:"",description:"",price:0.0,currency:""};
  JSON:any = {msg:"Bulding Customers And Products Index",code:200,info:[]};
  constructor(private api:ReporteService) { }
  searchValue: string = '';


  FILTER1:string = '';FILTER2:string = ''; FILTER3:string = ''; FILTER4:string = ''; FILTER5:string = '';

  DATA = {};
  showFilter:boolean = false;
  URL_FILTER = '';
  JSONisLoaded:boolean = false;
  test(val:string):void{ this.FILTER1 = val;}


  sendJSON():void{
    if (this.row['address']==""){
      this.row['address'] = "Sin dirección registrada";
    }
    if (this.row['description']==""){
      this.row['description'] = "Sin descripción registrada";
    }
    this.JSON['info'] = [this.row];
    console.log(this.JSON['info']);

    console.log(this.JSON);
    if (this.OVERRIDE_VALUE){
      this.deleteProduct(this.OVERRIDE_FREETXT,true);
    }
    this.api.postProduct(this.JSON).subscribe((res:any)=>{
      this.DONE=true;
      const e = document.getElementById('banner');
      if (res['statusCode']==200){
        this.alert_type="alert-success";
        this.MSG_TEXT[0] = "Éxito"
        this.MSG_TEXT[1] = "Se ha añadido el producto correctamente."
      }else{
        this.alert_type="alert-danger";
        this.MSG_TEXT[0] = "Error"
        this.MSG_TEXT[1] = "Ha habido un error al agregar el producto."
      }
      this.listIndexes();
      this.cleanForm();
      this.OVERRIDE_VALUE = false;
      setTimeout(()=>{this.DONE=false;},10000);
    })
  }
  listIndexes():void{
    this.api.getProducts().subscribe((res:any)=>{
      this.INFO = res['info'];
    });
  };
  fillForUpdate(i: any): void {
    const index = this.INFO.indexOf(i);
    console.log(index);
  
    const aux = this.INFO[index];
    console.log(aux);
  
    this.OVERRIDE_FREETXT = aux.freetxt;
    console.log(this.OVERRIDE_FREETXT);
  
    this.row = {cardCode: aux.cardcode,cardName:aux.cardname,address:aux.address,itemCode:aux.itemcode,freeTxt:aux.freetxt,description:aux.description,price:aux.price,currency:aux.currency};
    console.log(this.row);
  
  }
  cleanForm():void{this.row = {cardCode: "",cardName:"",address:"",itemCode:"",freeTxt:"",description:"",price:0.0,currency:""};}
  deleteProduct(index:any,override=false):void{
    if (override==false){
      const sure = window.confirm(`¿Deseas borrar el producto: ${index}?`);
      if (!sure){
        return
      }
    }
    this.api.deleteProduct(index).subscribe((res)=>{
      this.listIndexes();
      this.cleanForm();
      this.OVERRIDE_VALUE = false;
    });
  }
  ngOnInit(): void {
    this.listIndexes();
  }

}
