import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cargar-pedido',
  templateUrl: './cargar-pedido.component.html'
})
export class CargarPedidoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
