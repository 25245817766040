import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { createMapeCSV } from 'src/app/models/createMapeCSV';
import { SemaphoreService } from 'src/app/services/semaphore.service';

@Component({
  selector: 'app-mape',
  templateUrl: './mape.component.html',
  styleUrls: ['./mape.component.css']
})
export class MapeComponent implements OnInit {
  FILTER1:string = '';FILTER2:string = ''; FILTER3:string = ''; FILTER4:string = ''; 
  JSONisLoaded:boolean = false;
  URL_FILTER = '';
  DATA:any = [];
  HEADERS:boolean[] = [true,true,true,true,true,true,true,true,true,true,true,true,true];
  showFilter:boolean = false;
  constructor(private s:SemaphoreService,private r:Router) { }
  restartRange():void{this.URL_FILTER = ''; this.updateTable();}
  reload(){this.r.navigateByUrl('/mape').then(()=> window.location.reload());}
  filterByRange(data:string,condition:string,value:string):void{
    this.URL_FILTER = '?f=';
    const val = Number(value);
    switch(Number(data)){
      case 1 : this.URL_FILTER+='mape_'; break;
      case 2 : this.URL_FILTER+='minf_'; break;
      case 3 : this.URL_FILTER+='cogs_'; break;
      case 4 : this.URL_FILTER+='perc_';break;
    }
    if(condition==='<='){
      this.URL_FILTER+='menor_';
    } else if (condition==='=='){
      this.URL_FILTER+='igual_';
    } else {
      this.URL_FILTER+='mayor_';
    }
    this.URL_FILTER+=val;
    this.updateTable();
  }
  ngOnInit(): void {
    this.updateTable();
  }
  createCSV():void{
    const csv = new createMapeCSV(this.HEADERS);
    csv.exportCSV();
  }
  updateTable(){
    this.s.getMape(this.URL_FILTER).subscribe((res:any)=>{
      if(res["body"]){
      const aux = res.body;
      this.DATA = aux.map((i)=>{
        const x = i.data;
        return {MAPE_4 : x.MAPE_4,MAPE_2:x.MAPE_2,ABC : x.ABC,MAPE_3 : x.MAPE_3,MAPE_1:x.MAPE_1,Codigo:x.Codigo,Forecast_4:x.Forecast_4,minimo_facturado:x.minimo_facturado,Facturado_3:x.Facturado_3,Forecast_1:x.Forecast_1,Facturado_2:x.Facturado_2,cogs:x.cogs,Forecast_3:x.Forecast_3,Facturado_1:x.Facturado_1,Grupo:x.Grupo,Forecast_2:x.Forecast_2,Nivel_min:x.Nivel_min,mape:x.mape,Descripcion:x.Descripcion,porcentaje:x.porcentaje,Facturado_4:x.Facturado_4}
       });
      }
      this.JSONisLoaded = true;
    })
  }

}
